import React from "react";
import internet from "../../img/undraw_data_trends_re_2cdy.svg"
import "./notfound.css";
const Notfound = () => {
  return (
    <div className="internet">
      <h2>Not Found This Page 404</h2>

      <img src={internet} alt="internet" />
    </div>
  );
};

export default Notfound;
