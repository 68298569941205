import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./slider.css";
import { Fade } from "react-awesome-reveal";
import { db } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
const Slider = ({ Arabic }) => {
  const [Intros, setIntros] = useState([]);
  const [IntrosA, setIntrosA] = useState([]);

  useEffect(
    () =>
      onSnapshot(collection(db, "intro"), (snapshot) =>
        setIntros(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(collection(db, "introA"), (snapshot) =>
        setIntrosA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const PlayVideo = () => {
    const link = document.querySelectorAll(".car .vid");
    link.forEach((act) => {
      act.addEventListener("loadeddata", () => {
        act.removeAttribute("controls");
        act.play();
      });
    });
  };
  useEffect(() => {
    PlayVideo();
  }, []);
  return (
    <div className="car">
      {/* <Navbarr /> */}
      <div className="overlay"></div>
      {Arabic === "true" ? (
        <>
          {" "}
          {IntrosA && IntrosA.length >= 0
            ? IntrosA.map((item, idx) => (
                <Carousel fade key={idx}>
                  {item.videoA !== "" ? (
                    <Carousel.Item>
                      <video loop autoPlay muted className="vid d-block w-100">
                        <source src={item.videoA} />
                      </video>
                      <Carousel.Caption>
                        <div className="slider-caption">
                          <Fade direction="up">
                            <h1>
                              {item.titleA} <span>{item.wordA}</span>
                            </h1>
                            <p>{item.descA}</p>
                          </Fade>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ) : (
                    <Carousel.Item>
                      <img src={item.imageA} alt="" />
                      <Carousel.Caption>
                        <div className="slider-caption">
                          <Fade direction="up">
                            <h1>
                              {item.titleA} <span>{item.wordA}</span>
                            </h1>
                            <p>{item.descA}</p>
                          </Fade>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  )}

                  {/* <Carousel.Item>
          <video  loop autoPlay muted className="vid d-block w-100">
            <source src={v1} />
          </video>{" "}
          <Carousel.Caption>
            <Fade direction="up">
              <h1>
                Welcome To Futuer Leaders <span>School</span>
              </h1>
              <p>
                A distinctive, culturally-diverse, knowledge based and
                international school in which every student is motivated to
                learn and empowered to become a successful
              </p>
            </Fade>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <video  loop autoPlay muted className="vid d-block w-100">
            <source src={v1} />
          </video>{" "}
          <Carousel.Caption>
            <Fade direction="up">
              <h1>
                Welcome To Futuer Leaders <span>School</span>
              </h1>
              <p>
                A distinctive, culturally-diverse, knowledge based and
                international school in which every student is motivated to
                learn and empowered to become a successful
              </p>
            </Fade>
          </Carousel.Caption>
        </Carousel.Item> */}
                </Carousel>
              ))
            : null}
        </>
      ) : (
        <>
          {Intros && Intros.length >= 0
            ? Intros.map((item, idx) => (
                <Carousel fade key={idx}>
                  {item.video !== "" ? (
                    <Carousel.Item>
                      <video loop autoPlay muted className="vid d-block w-100">
                        <source src={item.video} />
                      </video>
                      <Carousel.Caption>
                        <div className="slider-caption">
                          <Fade direction="up">
                            <h1>
                              {item.title} <span>{item.word}</span>
                            </h1>
                            <p>{item.desc}</p>
                          </Fade>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ) : (
                    <Carousel.Item>
                      <img src={item.image} alt="" />
                      <Carousel.Caption>
                        <div className="slider-caption">
                          <Fade direction="up">
                            <h1>
                              {item.title} <span>{item.word}</span>
                            </h1>
                            <p>{item.desc}</p>
                          </Fade>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  )}

                  {/* <Carousel.Item>
          <video  loop autoPlay muted className="vid d-block w-100">
            <source src={v1} />
          </video>{" "}
          <Carousel.Caption>
            <Fade direction="up">
              <h1>
                Welcome To Futuer Leaders <span>School</span>
              </h1>
              <p>
                A distinctive, culturally-diverse, knowledge based and
                international school in which every student is motivated to
                learn and empowered to become a successful
              </p>
            </Fade>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <video  loop autoPlay muted className="vid d-block w-100">
            <source src={v1} />
          </video>{" "}
          <Carousel.Caption>
            <Fade direction="up">
              <h1>
                Welcome To Futuer Leaders <span>School</span>
              </h1>
              <p>
                A distinctive, culturally-diverse, knowledge based and
                international school in which every student is motivated to
                learn and empowered to become a successful
              </p>
            </Fade>
          </Carousel.Caption>
        </Carousel.Item> */}
                </Carousel>
              ))
            : null}
        </>
      )}
    </div>
  );
};

export default Slider;
