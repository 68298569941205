import React, { useEffect, useState } from "react";
import "./Aboutuspage.css";
import { Fade } from "react-awesome-reveal";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
const AboutUsPage = ({ title, desc, link ,Arabic}) => {
  const [Aboutuss, setAboutuss] = useState([]);
  const [AboutussA, setAboutussA] = useState([]);

  useEffect(
    () =>
      onSnapshot(query(collection(db, "infofooter"), orderBy("createdAt", "asc")), (snapshot) =>
        setAboutuss(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "infofooterA"), orderBy("createdAt", "asc")), (snapshot) =>
        setAboutussA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const [ab, setab] = useState([]);
  const [abA, setabA] = useState([]);

  useEffect(() => {
    const set = new Set();
    //eslint-disable-next-line
    Aboutuss.map((d) => {
      set.add(d.head);
    });
    const Cat = Array.from(set);
    setab(Cat);
  }, [Aboutuss]);
  
  useEffect(() => {
    const set = new Set();
    //eslint-disable-next-line
    AboutussA.map((d) => {
      set.add(d.headA);
    });
    const Cat = Array.from(set);
    setabA(Cat);
  }, [AboutussA]);
  return (
    <div className="facil uss">
      <section>
        <h3 className="tit ">{Arabic === "true" ? "من نحن" : "About Us"}</h3>
        <hr />
        {/* <h3 className='tit'>Virtual Touer</h3> */}
        {Arabic === "true" ?(<>
          {abA && abA.length >= 0
          ? abA.map((item, idx) => (
              <div className="container" key={idx}>
                <h3 className="text-center mt-3 mb-3">{item}</h3>
                {AboutussA && AboutussA.length >= 0
                  ? AboutussA.map((itemf, idxx) =>
                      item === itemf.headA ? (
                        <div
                          className="row"
                          style={{ marginBottom: "36px" }}
                          key={idxx}
                        >
                          <div className="col-md-12 ">
                            <Fade direction="left" triggerOnce="false">
                              {/* <h2 className="text-uppercase">{title}</h2> */}
                              <p>{itemf.descA}</p>
                            </Fade>
                          </div>
                          {/* <div className="col-md-6">
                            <Fade direction="right" triggerOnce="false">
                              {itemf.link !== "" ? (
                                <iframe
                                  title="myFramesd"
                                  width="80%"
                                  height="500"
                                  src={`https://www.youtube.com/embed/${itemf.link}`}
                                ></iframe>
                              ) : itemf.img !== "" ? (
                                <img  src={itemf.img} alt="" />
                              ) : null}
                            </Fade>
                          </div> */}
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            ))
          : null}</>):(<>
          {ab && ab.length >= 0
          ? ab.map((item, idx) => (
              <div className="container" key={idx}>
                <h3 className="text-center mt-3 mb-3">{item}</h3>
                {Aboutuss && Aboutuss.length >= 0
                  ? Aboutuss.map((itemf, idxx) =>
                      item === itemf.head ? (
                        <div
                          className="row"
                          style={{ marginBottom: "36px" }}
                          key={idxx}
                        >
                          <div className="col-md-12 ">
                            <Fade direction="left" triggerOnce="false">
                              {/* <h2 className="text-uppercase">{title}</h2> */}
                              <p>{itemf.desc}</p>
                            </Fade>
                          </div>
                          {/* <div className="col-md-6">
                            <Fade direction="right" triggerOnce="false">
                              {itemf.link !== "" ? (
                                <iframe
                                  title="myFramesd"
                                  width="80%"
                                  height="500"
                                  src={`https://www.youtube.com/embed/${itemf.link}`}
                                ></iframe>
                              ) : itemf.img !== "" ? (
                                <img  src={itemf.img} alt="" />
                              ) : null}
                            </Fade>
                          </div> */}
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            ))
          : null}</>)}
    
      </section>
    </div>
  );
};

export default AboutUsPage;
