import React, { useEffect, useState } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
const Footer = ({ Arabic }) => {
  const [LinksA, setLinksA] = useState([]);
  const [Links, setLinks] = useState([]);

  useEffect(
    () =>
      onSnapshot(collection(db, "socialmedia"), (snapshot) =>
        setLinks(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  useEffect(
    () =>
      onSnapshot(collection(db, "socialmediaA"), (snapshot) =>
        setLinksA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  return (
    <>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            {/* {Abouts && Abouts.length >= 0
              ? Abouts.map((item, idx) => (
                  <div className="col-sm-12 col-md-6" key={idx}>
                    <h6>{item.head}</h6>
                    <p className="text-justify">{item.desc}</p>
                  </div>
                ))
              : null} */}
            <div className="col-xs-6 col-md-6">
              {Arabic === "true" ? <h6>الموقع</h6> : <h6>Location</h6>}
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3507.2897642430607!2d30.86780798492109!3d28.470818982481997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI4JzE1LjAiTiAzMMKwNTEnNTYuMiJF!5e0!3m2!1sar!2seg!4v1701519517198!5m2!1sar!2seg"
                  width="100%"
                  height="100%"
                  title="Google Map"
                  frameBorder="0"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />
              </div>
            </div>
            <div className="col-xs-6 col-md-3">
              {Arabic === "true" ? <h6>روابط مختصرة</h6> : <h6>Quick Links</h6>}

              <ul className="footer-links">
                <li>
                  <Link to="/">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {Arabic === "true" ? "الرئيسية" : "Home"}
                  </Link>
                </li>
                <li>
                  <Link to="/galary">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {Arabic === "true" ? "الألبوم" : "Gallery"}
                  </Link>
                </li>
                <li>
                  <Link to="/activites">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {Arabic === "true" ? "الأنشطة" : "Activities"}
                  </Link>
                </li>
                <li>
                  <Link to="/facilities">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {Arabic === "true" ? "مرافق" : "Facilities"}
                  </Link>
                </li>
                <li>
                  <Link to="/admission">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {Arabic === "true" ? "التسجيل" : "Admission"}
                  </Link>
                </li>
                <li>
                  <Link to="/workwithuss">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {Arabic === "true" ? "اعمل معنا" : "Work with Us"}
                  </Link>
                </li>
                <li>
                  <Link to="/aboutuspage">
                    <i className="fas fa-chevron-right"></i>{" "}
                    {Arabic === "true" ? "معلومات عنا" : "About Us"}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="social-icons">
                {Arabic === "true"
                  ? LinksA && LinksA.length > 0
                    ? LinksA.map((item, idx) => (
                        <>
                          <li>
                            <a className="facebook" href={item.facebookA}>
                              <i className="fa-brands fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a className="twitter" href={item.twitterA}>
                              <i className="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a className="dribbble" href={item.youtubeA}>
                              <i className="fa-brands fa-youtube"></i>
                            </a>
                          </li>
                          <li>
                            <a className="linkedin" href={item.linkedinA}>
                              <i className="fa-brands fa-linkedin"></i>
                            </a>
                          </li>
                        </>
                      ))
                    : null
                  : Links && Links.length > 0
                  ? Links.map((item, idx) => (
                      <>
                        <>
                          <li>
                            <a className="facebook" href={item.facebook}>
                              <i className="fa-brands fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a className="twitter" href={item.twitter}>
                              <i className="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a className="dribbble" href={item.youtube}>
                              <i className="fa-brands fa-youtube"></i>
                            </a>
                          </li>
                          <li>
                            <a className="linkedin" href={item.linkedin}>
                              <i className="fa-brands fa-linkedin"></i>
                            </a>
                          </li>
                        </>
                      </>
                    ))
                  : null}
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="copyright-text">
                Designed by
                <a href="https://a3maalapps.com/">A3maal</a>
              </p>
            </div>

            {/* <div className="col-md-4 col-sm-6 col-xs-12">
   
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
