import React from "react";
import "./main.css";
import { Fade } from "react-awesome-reveal";
import dash from "../../img/undraw_data_trends_re_2cdy.svg";
const Mainadminpage = () => {
  return (
    <div className="container">
      <div className="mainadminpage">
        <Fade direction="left">
          {" "}
          <div className="infoo">
            <h2>Admin Panal</h2>
          </div>
        </Fade>
        <Fade direction="right">
          {" "}
          <div className="imges">
            <img src={dash} alt="" />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Mainadminpage;
