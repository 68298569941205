import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "./work.css";
import React, { useEffect, useState } from "react";
import defaultt from "../../img/icons8-person-64 1.png";
import { Container, Spinner } from "react-bootstrap";
import notify from "../Notifcation";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
const WorkwithusREG = ({ Arabic }) => {
  const [Datee, setDate] = useState("");
  const [picture, setpicture] = useState("");
  const [Imagesrc, setImagesrc] = useState("");
  const [picturebase64, setpicturebase64] = useState("");
  const [Cv, setCv] = useState("");
  const [Loadingimg, setLoadingimg] = useState(null);
  const [Loadingcv, setLoadingcv] = useState(null);
  const [FullNmae, setFullNmae] = useState("");
  const [Nationality, setNationality] = useState("");
  const [Residentloc, setResidentloc] = useState("");
  const [Address, setAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [Educationqu, setEducationqu] = useState("");
  const [Level, setLevel] = useState("");
  const [Email, setEmail] = useState("");
  const [JobTitle, setJobTitle] = useState("");
  const [PresentOcc, setPresentOcc] = useState("");
  const [ForeignLang1, setForeignLang1] = useState("");
  const [Level1, setLevel1] = useState("");
  const [ForeignLang2, setForeignLang2] = useState("");
  const [Level2, setLevel2] = useState("");
  const [ForeignLang3, setForeignLang3] = useState("");
  const [Level3, setLevel3] = useState("");
  const [Teachingex, setTeachingex] = useState("");
  const [Courses, setCourses] = useState("");
  const [Computercours, setComputercours] = useState("");
  const [Maritalstatus, setMaritalstatus] = useState("");
  const [Numberchild, setNumberchild] = useState("");
  const [Diseases, setDiseases] = useState("");
  const [Handycapping, setHandycapping] = useState("");
  const [Loading, setLoading] = useState("");
  const renderPreview = () => {
    if (Imagesrc) {
      const reader = new FileReader();
      reader.readAsDataURL(Imagesrc);
      reader.onloadend = () => {
        setpicturebase64(reader.result);
      };
      return <img id="profile" src={picturebase64} alt="your profile pic" />;
    } else {
      return <img src={defaultt} alt="default profile pic" />;
    }
  };
  const fileUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      setLoadingimg(true);
      setImagesrc(file);
      const storageRefp = ref(storage, `image/${file.name}`);
      uploadBytes(storageRefp, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setpicture(downloadURL);
          setLoadingimg(false);
        });
      });
    }
  };
  const handleCVUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this file the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      setLoadingcv(true);
      const storageRefc = ref(storage, `cv/${file.name}`);
      uploadBytes(storageRefc, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setCv(downloadURL);
          setLoadingcv(false);
        });
      });
    }
  };
  const handleSubmit = async () => {
    // Upload the file
    if (
      FullNmae === "" ||
      Nationality === "" ||
      Datee === "" ||
      Address === "" ||
      Phone === "" ||
      Cv === ""
    ) {
      notify("Please Enter the require data", "warn");
      return;
    }
    setLoading(true);

    await addDoc(collection(db, "workwithus"), {
      Address: Address,
      Birthdate: Datee,
      Diseases: Diseases,
      Courses: Courses,
      ComputerCour: Computercours,
      Educationqu: Educationqu,
      Email: Email,
      ForeignLang1: ForeignLang1,
      Level: Level1,
      ForeignLang2: ForeignLang2,
      Level2: Level2,
      ForeignLang3: ForeignLang3,
      Level3: Level3,
      FullName: FullNmae,
      Handycapping: Handycapping,
      Jobtitle: JobTitle,
      Numberchild: Numberchild,
      Nationality: Nationality,
      Maritalstatus: Maritalstatus,
      picture: picture,
      cv: Cv,
      Teachingex: Teachingex,
      Residentlocation: Residentloc,
      PresentOcc: PresentOcc,
      Phone: Phone,
      level: Level,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoading(false);
        notify("Data Added successfuly", "success");
        setDate("");
        setpicture("");
        setImagesrc("");
        setpicturebase64("");
        setCv("");
        setFullNmae("");
        setNationality("");
        setResidentloc("");
        setAddress("");
        setPhone("");
        setEducationqu("");
        setLevel("");
        setEmail("");
        setJobTitle("");
        setPresentOcc("");
        setForeignLang1("");
        setLevel1("");
        setForeignLang2("");
        setLevel2("");
        setForeignLang3("");
        setLevel3("");
        setTeachingex("");
        setCourses("");
        setComputercours("");
        setMaritalstatus("");
        setNumberchild("");
        setDiseases("");
        setHandycapping("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setDate("");
        setpicture("");
        setpicturebase64("");
        setCv("");
        setFullNmae("");
        setNationality("");
        setResidentloc("");
        setAddress("");
        setImagesrc("");
        setPhone("");
        setEducationqu("");
        setLevel("");
        setEmail("");
        setJobTitle("");
        setPresentOcc("");
        setForeignLang1("");
        setLevel1("");
        setForeignLang2("");
        setLevel2("");
        setForeignLang3("");
        setLevel3("");
        setTeachingex("");
        setCourses("");
        setComputercours("");
        setMaritalstatus("");
        setNumberchild("");
        setDiseases("");
        setHandycapping("");
      });
  };

  return (
    <div className="workus">
      <Container>
        <h1 className="tit text-center" style={{ fontFamily: "Almarai" }}>
          {Arabic === "true" ? "وظائف" : "Work with us"}
        </h1>
        <hr />
        <div className="inputs-container">
          <h2
            style={{
              display: "block",
              textAlign: "center",
              width: "100%",
              fontFamily: "Almarai",
            }}
          >
            {Arabic === "true" ? "البيانات الأساسية" : "Basic Information"}
          </h2>
          <div style={{ display: "block", textAlign: "center", width: "100%" }}>
            <div className="proimg">
              <div className="imgprof">
                {renderPreview()}
                {Loadingimg ? (
                  <div>
                    <Spinner />
                  </div>
                ) : null}
              </div>
              <div className="image-editor">
                <i className="fas fa-pen editpic"></i>
                <input
                  type="file"
                  accept="image/*"
                  id="imginput"
                  onChange={fileUpload}
                />
              </div>
            </div>
          </div>
          <div className="inp-container">
            <TextField
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "الأسم بالكامل" : "Full Name"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              type="text"
              value={FullNmae}
              onChange={(e) => setFullNmae(e.target.value)}
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              value={Nationality}
              onChange={(e) => setNationality(e.target.value)}
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "الجنسية" : "Nationality"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true" ? "موقع الأقامة" : "Resident Location"
              }`}
              value={Residentloc}
              onChange={(e) => setResidentloc(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <div className="dateee">{Datee ? Datee : null}</div>
            <i className="ty fa-solid fa-calendar-days"></i>
            <TextField
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "تاريخ الميلاد" : "Birth Date"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              variant="outlined"
              type="date"
              value={Datee}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <TextField
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "العنوان" : "Address"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              value={Phone}
              onChange={(e) => setPhone(e.target.value)}
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "رقم الهاتف" : "Phone"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              type="number"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              value={Educationqu}
              onChange={(e) => setEducationqu(e.target.value)}
              label={`${
                Arabic === "true"
                  ? "المؤهلات التعليمية"
                  : "Education Qualifications"
              }`}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "المستوى" : "Level"}`}
              value={Level}
              onChange={(e) => setLevel(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "البريد الألكتوني" : "Email"}`}
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "عنوان الوظيفة" : "Job Title"}`}
              value={JobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              value={PresentOcc}
              onChange={(e) => setPresentOcc(e.target.value)}
              label={`${
                Arabic === "true" ? "الوظيفة الحالية" : "Present Occupation"
              }`}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              type="file"
              focused
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "السيرة الذاتية" : "CV"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              onChange={handleCVUpload}
            />
            {Loadingcv ? (
              <div>
                <Spinner />
              </div>
            ) : null}
          </div>
          <div className="inp-container"></div>
        </div>
        <div className="inputs-container">
          <h2
            style={{
              display: "block",
              textAlign: "center",
              width: "100%",
              fontFamily: "Almarai",
            }}
          >
            {Arabic === "true" ? "بيانات اضافية" : "Optional Information"}
          </h2>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true"
                  ? "اللغة الأجنبية الأولى"
                  : "Foreign Languages Spoken 1"
              }`}
              type="text"
              variant="outlined"
              value={ForeignLang1}
              onChange={(e) => setForeignLang1(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "المستوى" : "Level"}`}
              value={Level1}
              onChange={(e) => setLevel1(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              value={ForeignLang2}
              onChange={(e) => setForeignLang2(e.target.value)}
              label={`${
                Arabic === "true"
                  ? "اللغة الأجنبية الثانية"
                  : "Foreign Languages Spoken 2"
              }`}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "المستوى" : "Level"}`}
              value={Level2}
              onChange={(e) => setLevel2(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true"
                  ? "اللغة الأجنبية الثالثة"
                  : "Foreign Languages Spoken 3"
              }`}
              type="text"
              value={ForeignLang3}
              onChange={(e) => setForeignLang3(e.target.value)}
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "المستوى" : "Level"}`}
              value={Level3}
              onChange={(e) => setLevel3(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true"
                  ? "الخبرة في التدريس داخل جمهورية مصر العربية"
                  : "Teaching Experience Inside Arab Republic of Egypt"
              }`}
              type="text"
              variant="outlined"
              value={Teachingex}
              onChange={(e) => setTeachingex(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true"
                  ? "الدورات ومواعيد الحصول عليها"
                  : "Courses and its Obtaining dates"
              }`}
              value={Courses}
              onChange={(e) => setCourses(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true" ? "دورات الكمبيوتر" : "Computer Courses"
              }`}
              value={Computercours}
              onChange={(e) => setComputercours(e.target.value)}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {" "}
                {Arabic === "true" ? "الحالة الاجتماعية" : "Marital Status"}
              </InputLabel>
              <Select
                value={Maritalstatus}
                onChange={(e) => setMaritalstatus(e.target.value)}
                // value={Chosedur}
                label={`${
                  Arabic === "true" ? "الحالة الاجتماعية" : "Marital Status"
                }`}
                // onChange={(e) => setChosedur(e.target.value)}
              >
                <MenuItem value={"Single"}>Single</MenuItem>
                <MenuItem value={"Marred"}>Marred</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true" ? "عدد الأبناء" : "Number of children"
              }`}
              type="number"
              value={Numberchild}
              onChange={(e) => setNumberchild(e.target.value)}
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              value={Diseases}
              onChange={(e) => setDiseases(e.target.value)}
              label={`${
                Arabic === "true"
                  ? "هل لديك أمراض - قم بإدراجها؟"
                  : "Do you have diseases - list it?"
              }`}
              type="text"
              variant="outlined"
            />
          </div>
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true"
                  ? "هل أنت من ذوي الهمم - قم بإدراج تحدياتك"
                  : "Do you have handycapping - list it"
              }`}
              type="text"
              value={Handycapping}
              onChange={(e) => setHandycapping(e.target.value)}
              variant="outlined"
            />
          </div>
          <div className="inp-container"></div>{" "}
          <div className="inp-container"></div>{" "}
          <div className="inp-container"></div>
        </div>
        {Loading ? (
          <button className="buttonadd">
            <Spinner />
          </button>
        ) : (
          <button
            style={{ fontFamily: "Almarai" }}
            className="buttonadd"
            onClick={handleSubmit}
          >
            {Arabic === "true" ? "إرسال" : "Send"}
          </button>
        )}
      </Container>{" "}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default WorkwithusREG;
