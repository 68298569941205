import React from "react";
import "./layout.css";
import Sidebar from "../sidebar/sidebar";
import Navbarr from "../../navbar/navbar";
import Footer from "../../footer/footer";
const Layout = ({ children, not,setArabic ,Arabic}) => {
  return (
    <div className={`${not !== null ? "layoutadmin" : "layout"}`}>
      {not !== null ? (
        <Sidebar  />
      ) : (
        <Navbarr Arabic={Arabic} setArabic={setArabic}/>
      )}
      {children}
      {not !== null ? null : <Footer Arabic={Arabic}/>}
    </div>
  );
};

export default Layout;
