import React, { useEffect, useState } from "react";
import "./facilities.css";
import { Fade } from "react-awesome-reveal";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
const Facilities = ({ title, desc, link, Arabic}) => { 
  const [Facilities, setFacilities] = useState([]);
  const [FacilitiesA, setFacilitiesA] = useState([]);

  useEffect(
    () =>
      onSnapshot(query(collection(db, "facilities"), orderBy("createdAt", "asc")), (snapshot) =>
        setFacilities(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "facilitiesA"), orderBy("createdAt", "asc")), (snapshot) =>
        setFacilitiesA(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  const [Fac, setFac] = useState([]);
  const [FacA, setFacA] = useState([]);

  useEffect(() => {
    const set = new Set();
    //eslint-disable-next-line
    Facilities.map((d) => {
      set.add(d.head);
    });
    const Cat = Array.from(set);
    setFac(Cat);
  }, [Facilities]);
  useEffect(() => {
    const set = new Set();
    //eslint-disable-next-line
    FacilitiesA.map((d) => {
      set.add(d.headA);
    });
    const Cat = Array.from(set);
    setFacA(Cat);
  }, [FacilitiesA]);
  return (
    <div className="facil">
      <section>
        <h3 className="tit ">{Arabic === "true" ? "مرافق المدرسة" : "Facilities"}</h3>
        <hr />
        {/* <h3 className='tit'>Virtual Touer</h3> */}
        {Arabic === "true"?(<> {FacA && FacA.length >= 0
          ? FacA.map((item, idx) => (
              <div className="container" key={idx}>
                <h3 className="text-center mt-3 mb-3">{item}</h3>
                {FacilitiesA && FacilitiesA.length >= 0
                  ? FacilitiesA.map((itemf, idxx) =>
                      item === itemf.headA ? (
                        <div className="row"style={{marginBottom:"36px"}} key={idxx}>
                          <div className="col-md-6 ">
                            <Fade direction="left" triggerOnce="false">
                              {/* <h2 className="text-uppercase">{title}</h2> */}
                              <p>{itemf.descA}</p>

                              {/* <Link
                className="btn btn-primary text-uppercase"
                to={link}
              >
                More 
              </Link> */}
                            </Fade>
                          </div>
                          <div className="col-md-6">
                            <Fade direction="right" triggerOnce="false">
                              {itemf.linkA !== "" ? (
                                <iframe
                                  title="myFramesd"
                                  width="80%"
                                  height="500"
                                  src={`https://www.youtube.com/embed/${itemf.linkA}`}
                                ></iframe>
                              ) : itemf.imgA !== "" ? (
                                <img  src={itemf.imgA} alt="" />
                              ) : null}
                            </Fade>
                          </div>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            ))
          : null}</>):(<>
         {Fac && Fac.length >= 0
          ? Fac.map((item, idx) => (
              <div className="container" key={idx}>
                <h3 className="text-center mt-3 mb-3">{item}</h3>
                {Facilities && Facilities.length >= 0
                  ? Facilities.map((itemf, idxx) =>
                      item === itemf.head ? (
                        <div className="row"style={{marginBottom:"36px"}} key={idxx}>
                          <div className="col-md-6 ">
                            <Fade direction="left" triggerOnce="false">
                              {/* <h2 className="text-uppercase">{title}</h2> */}
                              <p>{itemf.desc}</p>

                              {/* <Link
                className="btn btn-primary text-uppercase"
                to={link}
              >
                More 
              </Link> */}
                            </Fade>
                          </div>
                          <div className="col-md-6">
                            <Fade direction="right" triggerOnce="false">
                              {itemf.link !== "" ? (
                                <iframe
                                  title="myFramesd"
                                  width="80%"
                                  height="500"
                                  src={`https://www.youtube.com/embed/${itemf.link}`}
                                ></iframe>
                              ) : itemf.img !== "" ? (
                                <img  src={itemf.img} alt="" />
                              ) : null}
                            </Fade>
                          </div>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            ))
          : null}</>)}
               
      </section>
    </div>
  );
};

export default Facilities;
