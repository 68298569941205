import React, { useEffect, useState } from "react";
import "./activetes.css";
import { Fade } from "react-awesome-reveal";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
const Activetes = ({ Arabic }) => {
  const [Eventsyoutube, setEventsyoutube] = useState([]);
  const [EventsyoutubeA, setEventsyoutubeA] = useState([]);

  useEffect(
    () =>
      onSnapshot(query(collection(db, "events"), orderBy("createdAt", "asc")), (snapshot) =>
        setEventsyoutube(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "eventsA"), orderBy("createdAt", "asc")), (snapshot) =>
        setEventsyoutubeA(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  return (
    <div className="activites">
      <section>
        <h3 className="tit"> {Arabic === "true" ? "الأنشطة" : "Activites"}</h3>
        {/* <hr/> */}
        {/* <h3 className='tit'>Virtual Touer</h3> */}
        <div className="container">
        {Arabic === "true" ?(<>
          {EventsyoutubeA && EventsyoutubeA.length >= 0
            ? EventsyoutubeA.map((item, idx) => (
                <div className="row" key={idx}>
                  <div className="col-md-6 ">
                    <Fade direction="left" triggerOnce="false">
                      <h2 className="text-uppercase">{item.headA}</h2>
                      <p>{item.descA}</p>
                    </Fade>
                  </div>

                  <div className="col-md-6">
                    <Fade direction="right" triggerOnce="false">
                      <iframe
                        title="myFramee"
                        width="80%"
                        height="500"
                        src={`https://www.youtube.com/embed/${item.linkA}`}
                      ></iframe>
                    </Fade>
                  </div>
                </div>
              ))
            : null}</>):(<>
          {Eventsyoutube && Eventsyoutube.length >= 0
            ? Eventsyoutube.map((item, idx) => (
                <div className="row" key={idx}>
                  <div className="col-md-6 ">
                    <Fade direction="left" triggerOnce="false">
                      <h2 className="text-uppercase">{item.head}</h2>
                      <p>{item.desc}</p>
                    </Fade>
                  </div>

                  <div className="col-md-6">
                    <Fade direction="right" triggerOnce="false">
                      <iframe
                        title="myFramee"
                        width="80%"
                        height="500"
                        src={`https://www.youtube.com/embed/${item.link}`}
                      ></iframe>
                    </Fade>
                  </div>
                </div>
              ))
            : null}</>)}
        
        </div>
      </section>
    </div>
  );
};

export default Activetes;
