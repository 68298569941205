import React, { useContext, useState } from "react";
import "./App.css";
import Internet from "./components/internet/internet";
import Login from "./components/login/login";
import Homepage from "./components/homepage/homepage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "./context/auth";
import ProtectedRoute from "./context/privateroute";
import Main from "./components/dashboard/main";
import Intro from "./components/dashboard/intro/intro";
import Blog from "./components/dashboard/blog/blog";
import Achievement from "./components/dashboard/achievement/achievement";
import Featuer from "./components/dashboard/featuer/featuer";
import Contactus from "./components/dashboard/contactus/contactus";
import About from "./components/dashboard/about/about";
import Layout from "./components/dashboard/layout/layout";
import Admission from "./components/dashboard/admission/admission";
import Calender from "./components/dashboard/calender/calender";
import Workwithus from "./components/dashboard/work with us/workwithus";
import WorkwithusREG from "./components/workwithus/workwithus";
import AdmissionREG from "./components/admission/admission";
import Notfound from "./components/notfound/notfound";
import Activetes from "./components/activetes/activetes";
import Calenderclients from "./components/calender/calender";
import Popupflag from "./components/Popupflag/Popupflag";
import EventsYou from "./components/dashboard/eventsyou/eventsyou";
import Forgetpassword from "./components/forgetpassword/forgetpassword";
import CalenderclientsAmerican from "./components/calenderamerican/calenderamerican";
import CalenderclientsArabic from "./components/calenderarabic/calenderarabic";
import CalenderAmericand from "./components/dashboard/calenderamerican/calenderamerican";
import CalenderArabicd from "./components/dashboard/calenderarabic/calenderarabic";
import Facilities from "./components/facilities/facilities";
import Optionadmission from "./components/dashboard/optionadmission/optionadmission";
import Facilitiesadmin from "./components/dashboard/facilities/facilities";
import Infofooter from "./components/dashboard/infofooter/infofooter";
import AboutUsPage from "./components/Aboutuspage/Aboutuspage";
import Socialmediaa from "./components/dashboard/socialmedia/socialmedia";
function App() {
  const { user } = useContext(AuthContext);
  const [Arabic, setArabic] = useState(localStorage.Arabic);
  return (
    <div className={`app ${Arabic==="true"?'arabfont':'engfont'}`}>
      <BrowserRouter>
        <>
          {navigator.onLine === false? (
            <Internet />
          ) : (
            <>
              <Layout setArabic={setArabic} Arabic={Arabic} not={user}>
                <Routes>
                  <Route path="/login-skjf5446we5w54f" element={<Login />} />
                  <Route path="/forgetpassword" element={<Forgetpassword />} />
                  <Route path="/" element={<Homepage  Arabic={Arabic} />} />
                  <Route
                    path="/workwithuss"
                    element={<WorkwithusREG Arabic={Arabic} />}
                  />
                  <Route
                    path="/admission"
                    element={<AdmissionREG Arabic={Arabic} />}
                  />
                       <Route
                    path="/aboutuspage"
                    element={<AboutUsPage Arabic={Arabic} />}
                  />
                  <Route path="/activites" element={<Activetes  Arabic={Arabic} />} />
                  <Route path="/calenderclient" element={<Calenderclients />} />
                  <Route
                    path="/calenderclientarabic"
                    element={<CalenderclientsArabic Arabic={Arabic} />}
                  />
                  <Route
                    path="/calenderclientamerican"
                    element={<CalenderclientsAmerican />}
                  />
                  <Route path="/galary" element={<Popupflag Arabic={Arabic} />} />
                  <Route path="/facilities" element={<Facilities   Arabic={Arabic}/>} />
                  <Route element={<ProtectedRoute auth={user} />}>
                    <Route path="/admin" element={<Main />} />
                    <Route path="/admin/intro" element={<Intro />} />
                    <Route path="/admin/galary" element={<Blog />} />
                    <Route path="/admin/socialmedia" element={<Socialmediaa />} />

                    <Route
                      path="/admin/achievements" 
                      element={<Achievement />}
                    />
                    <Route path="/admin/values" element={<Featuer />} />
                    <Route path="/admin/contact" element={<Contactus />} />
                    <Route path="/admin/about" element={<About />} />
                    <Route path="/admin/aboutuspage" element={<Infofooter />} />
                    <Route
                      path="/admin/admission"
                      element={
                        <Admission setArabic={setArabic} Arabic={Arabic} />
                      }
                    />
                    <Route
                      path="/admin/admissionoptions"
                      element={<Optionadmission />}
                    />
                    <Route path="/admin/calender" element={<Calender />} />
                    <Route
                      path="/admin/calenderarabic"
                      element={<CalenderArabicd />}
                    />
                    <Route
                      path="/admin/facilities"
                      element={<Facilitiesadmin />}
                    />
                    <Route
                      path="/admin/calenderamerican"
                      element={<CalenderAmericand />}
                    />
                    <Route
                      path="/admin/workwithus"
                      element={
                        <Workwithus setArabic={setArabic} Arabic={Arabic} />
                      }
                    />
                    <Route path="/admin/activities" element={<EventsYou />} />
                  </Route>
                  <Route path="404" element={<Notfound />} />
                  <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
              </Layout>
            </>
          )}
        </>
      </BrowserRouter>
    </div>
  );
}
export default App;
