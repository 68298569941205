import React, { useEffect, useState } from "react";
import "./infofooter.css";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  updateDoc,
  onSnapshot,
  addDoc,
  deleteDoc,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import { TextField } from "@mui/material";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import notify from "../../Notifcation";
import { ToastContainer } from "react-toastify";
const Infofooter = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);

  const [headA, setHeadA] = useState("");
  const [descA, setdescA] = useState("");
  const [IdA, setIdA] = useState(null);
  const [LoadingA, setLoadingA] = useState(null);
  const [IseditA, setIseditA] = useState(false);
  const [showDeleteA, setshowDeleteA] = useState(false);
  const [ItemidA, setItemidA] = useState(false);
  const handleCloseDeleteA = () => setshowDelete(false);
  const handleShoweDeleteA = (itemid) => {
    setshowDeleteA(true);
    setItemidA(itemid);
  };
  const [InfofooterA, setInfofooterA] = useState([]);

  const [head, setHead] = useState("");
  const [desc, setdesc] = useState("");
  const [Id, setId] = useState(null);
  const [Loading, setLoading] = useState(null);
  const [Isedit, setIsedit] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  const [Infofooter, setInfofooter] = useState([]);
  useEffect(
    () =>
      onSnapshot(query(collection(db, "infofooter"), orderBy("createdAt", "asc")), (snapshot) =>
        setInfofooter(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );

  useEffect(
    () =>
      onSnapshot(query(collection(db, "infofooterA"), orderBy("createdAt", "asc")), (snapshot) =>
        setInfofooterA(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );

  const submitInfofooter = async (e) => {
    e.preventDefault();
    if (head === "" || desc === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoading(true);
      await addDoc(collection(db, "infofooter"), {
        head,
        desc,
        createdAt: Timestamp.now(),
      });
      setLoading(false);
      notify("Data Added successfuly", "success");
      setHead("");
      setdesc("");
      setIsedit(false);
    } catch (error) {
      notify("Please Try Again", "error");
      setHead("");
      setdesc("");
      setIsedit(false);
    }
  };
  const update = (item) => {
    setIsedit(true);
    setHead(item.head);
    setId(item.id);
    setdesc(item.desc);
  };
  const handleDelete = async () => {
    setLoading(true);

    const deleteVal = doc(db, "infofooter", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setLoading(false);
    setHead("");
    setdesc("");
    setIsedit(false);
    setshowDelete(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (head === "" || desc === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "infofooter", Id);
    await updateDoc(updateData, {
      head,
      desc,
      createdAt: Timestamp.now(),
    });
    notify("Event Edited successfuly", "success");
    setLoading(false);
    setHead("");
    setdesc("");
    setId("");
    setIsedit(false);
  };

  const submitInfofooterA = async (e) => {
    e.preventDefault();
    if (headA === "" || descA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoadingA(true);
      await addDoc(collection(db, "infofooterA"), {
        headA,
        descA,
        createdAt: Timestamp.now(),
      });
      setLoadingA(false);
      notify("Data Added successfuly", "success");
      setHeadA("");
      setdescA("");
      setIseditA(false);
    } catch (error) {
      notify("Please Try Again", "error");
      setHeadA("");
      setdescA("");
      setIseditA(false);
    }
  };
  const updateA = (item) => {
    setIseditA(true);
    setHeadA(item.headA);
    setIdA(item.id);
    setdescA(item.descA);
  };
  const handleDeleteA = async () => {
    setLoadingA(true);
    const deleteVal = doc(db, "infofooterA", ItemidA);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setLoadingA(false);
    setHeadA("");
    setdescA("");
    setIseditA(false);
    setshowDeleteA(false);
  };

  const handleUpdateA = async () => {
    setLoadingA(true);
    if (headA === "" || descA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "infofooterA", IdA);
    await updateDoc(updateData, {
      headA,
      descA,
      createdAt: Timestamp.now(),
    });
    notify("Event Edited successfuly", "success");
    setLoadingA(false);
    setHeadA("");
    setdescA("");
    setIdA("");
    setIseditA(false);
  };
  return (
    <div className="achivement">
      <h1>About Us Page</h1>
      <div className="cch">
        <div className="cchs">
          اللغة العربية{" "}
          <input
            type="checkbox"
            checked={Arabic}
            onChange={(e) => setArabic(e.target.checked)}
          />
        </div>
        <div className="cchs">
          اللغة الإنجليزية{" "}
          <input
            type="checkbox"
            checked={English}
            onChange={(e) => setEnglish(e.target.checked)}
          />
        </div>
      </div>
      {Arabic ? (
        <>
          {" "}
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="العنوان"
                  type="text"
                  variant="outlined"
                  value={headA}
                  onChange={(e) => setHeadA(e.target.value)}

                  //   className="ww"
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="الوصف"
                  variant="outlined"
                  value={descA}
                  onChange={(e) => setdescA(e.target.value)}
                />
              </div>{" "}
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {IseditA ? (
              LoadingA ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdateA}>
                  Edit{" "}
                </button>
              )
            ) : LoadingA ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitInfofooterA}>
                Add{" "}
              </button>
            )}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    العنوان <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    الوصف <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {InfofooterA && InfofooterA.length >= 0
                  ? InfofooterA.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.headA}</td>
                        <td>{item.descA}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => updateA(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDeleteA(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      ) : (
        <>
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="Title"
                  type="text"
                  variant="outlined"
                  value={head}
                  onChange={(e) => setHead(e.target.value)}

                  //   className="ww"
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="description"
                  variant="outlined"
                  value={desc}
                  onChange={(e) => setdesc(e.target.value)}
                />
              </div>{" "}
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {Isedit ? (
              Loading ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdate}>
                  Edit{" "}
                </button>
              )
            ) : Loading ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitInfofooter}>
                Add{" "}
              </button>
            )}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Title <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    Description <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Infofooter && Infofooter.length >= 0
                  ? Infofooter.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.head}</td>
                        <td>{item.desc}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => update(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDelete(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      )}

      <ToastContainer position="top-center" />
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDelete}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteA}
        onHide={handleCloseDeleteA}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDeleteA}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDeleteA}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Infofooter;
