import React, { useEffect, useState } from "react";
import "./achievement.css";
import { db } from "../../../firebase";
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  onSnapshot,
  serverTimestamp,
  orderBy,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import notify from "../../Notifcation";
import { ToastContainer } from "react-toastify";
const Achievement = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);

  const [headA, setHeadA] = useState("");
  const [descA, setdescA] = useState("");
  const [IdA, setIdA] = useState(null);
  const [LoadingA, setLoadingA] = useState(null);
  const [YearA, setYearA] = useState("");
  const [IseditA, setIseditA] = useState(false);
  const [DirectionA, setDirectionA] = useState("");
  const [AchivementsA, setAchivementsA] = useState([]);
  const [showDeleteA, setshowDeleteA] = useState(false);
  const [ItemidA, setItemidA] = useState(false);

  const [head, setHead] = useState("");
  const [desc, setdesc] = useState("");
  const [Id, setId] = useState(null);
  const [Loading, setLoading] = useState(null);
  const [Year, setYear] = useState("");
  const [Isedit, setIsedit] = useState(false);
  const [Direction, setDirection] = useState("");
  const [Achivements, setAchivements] = useState([]);
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);

  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };

  const handleCloseDeleteA = () => setshowDeleteA(false);
  const handleShoweDeleteA = (itemid) => {
    setshowDeleteA(true);
    setItemidA(itemid);
  };
  useEffect(
    () =>
      onSnapshot( query(collection(db, "Achivements"), orderBy("createdAt", "asc")), (snapshot) =>
        setAchivements(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "AchivementsA"), orderBy("createdAt", "asc")),
        (snapshot) =>
          setAchivementsA(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          )
      ),
    []
  );
  const submitAchievements = async (e) => {
    e.preventDefault();
    if (head === "" || Year === "" || Direction === "" || desc === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoading(true);
      await addDoc(collection(db, "Achivements"), {
        head,
        desc,
        year: Year,
        direction: Direction,
        createdAt: Timestamp.now(),
      });
      setLoading(false);
      notify("Data Sent successfuly", "success");
      setHead("");
      setdesc("");
      setYear("");
      setdesc("");
      setIsedit(false);
    } catch (error) {
      notify("Please Try Again", "error");
      setHead("");
      setdesc("");
      setYear("");
      setdesc("");
      setIsedit(false);
    }
  };
  const update = (item) => {
    setIsedit(true);
    setDirection(item.direction);
    setHead(item.head);
    setYear(item.year);
    setId(item.id);
    setdesc(item.desc);
  };
  const handleDelete = async () => {
    setLoading(true);
    const deleteVal = doc(db, "Achivements", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setLoading(false);
    setHead("");
    setdesc("");
    setId("");
    setYear("");
    setdesc("");
    setIsedit(false);
    setshowDelete(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (head === "" || Year === "" || Direction === "" || desc === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "Achivements", Id);
    await updateDoc(updateData, {
      head,
      desc,
      year: Year,
      direction: Direction,
      createdAt: Timestamp.now(),
    });
    notify("Event Edited successfuly", "success");
    setLoading(false);
    setHead("");
    setdesc("");
    setYear("");
    setdesc("");
    setId("");
    setIsedit(false);
  };

  const submitAchievementsA = async (e) => {
    e.preventDefault();
    if (headA === "" || YearA === "" || DirectionA === "" || descA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoadingA(true);

      await addDoc(collection(db, "AchivementsA"), {
        headA,
        descA,
        yearA: YearA,
        directionA: DirectionA,
        createdAt: Timestamp.now(),
      });
      setLoadingA(false);
      notify("Data Sent successfuly", "success");
      setHeadA("");
      setdescA("");
      setYearA("");
      setIseditA(false);
    } catch (error) {
      notify("Please Try Again", "error");
      setHeadA("");
      setdescA("");
      setYearA("");
      setdescA("");
      setIseditA(false);
    }
  };
  const updateA = (item) => {
    setIseditA(true);
    setDirectionA(item.directionA);
    setHeadA(item.headA);
    setYearA(item.yearA);
    setIdA(item.id);
    setdescA(item.descA);
  };
  const handleDeleteA = async () => {
    setLoadingA(true);
    const deleteVal = doc(db, "AchivementsA", ItemidA);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setLoadingA(false);
    setHeadA("");
    setdescA("");
    setIdA("");
    setYearA("");
    setdescA("");
    setIseditA(false);
    setshowDeleteA(false);
  };

  const handleUpdateA = async () => {
    setLoadingA(true);
    if (headA === "" || YearA === "" || DirectionA === "" || descA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "AchivementsA", IdA);
    await updateDoc(updateData, {
      headA,
      descA,
      yearA: YearA,
      direction: DirectionA,
      createdAt: Timestamp.now(),
    });
    notify("Event Edited successfuly", "success");
    setLoadingA(false);
    setHeadA("");
    setdescA("");
    setYearA("");
    setdescA("");
    setIdA("");
    setIseditA(false);
  };
  return (
    <div className="achivement">
      <h1>Our Achievements</h1>
      <div className="cch">
        <div className="cchs">
          اللغة العربية{" "}
          <input
            type="checkbox"
            checked={Arabic}
            onChange={(e) => setArabic(e.target.checked)}
          />
        </div>
        <div className="cchs">
          اللغة الإنجليزية{" "}
          <input
            type="checkbox"
            checked={English}
            onChange={(e) => setEnglish(e.target.checked)}
          />
        </div>
      </div>
      {Arabic ? (
        <>
          {" "}
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="السنة"
                  type="number"
                  variant="outlined"
                  value={YearA}
                  onChange={(e) => setYearA(e.target.value)}

                  //   className="ww"
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="العنوان"
                  type="text"
                  variant="outlined"
                  value={headA}
                  onChange={(e) => setHeadA(e.target.value)}

                  //   className="ww"
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="الوصف"
                  variant="outlined"
                  value={descA}
                  onChange={(e) => setdescA(e.target.value)}
                />
              </div>{" "}
              <div className="inp-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    الأتجاه
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    onChange={(e) => setDirectionA(e.target.value)}
                    id="demo-simple-select"
                    value={DirectionA}
                    // value={Chosedur}
                    label={"الأتجاه"}
                    // onChange={(e) => setChosedur(e.target.value)}
                  >
                    <MenuItem value={"left"}>left</MenuItem>
                    <MenuItem value={"right"}>right</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {IseditA ? (
              LoadingA ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdateA}>
                  Edit
                </button>
              )
            ) : Loading ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitAchievementsA}>
                Add
              </button>
            )}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    السنة <i className="fa-solid fa-user"></i>
                  </th>
                  <th>
                    العنوان <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    الوصف <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th>
                    الأتجاه <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {AchivementsA && AchivementsA.length >= 0
                  ? AchivementsA.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.yearA}</td>
                        <td>{item.headA}</td>
                        <td>{item.descA}</td>
                        <td>{item.directionA}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => updateA(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDeleteA(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      ) : (
        <>
          {" "}
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="year"
                  type="number"
                  variant="outlined"
                  value={Year}
                  onChange={(e) => setYear(e.target.value)}

                  //   className="ww"
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="Title"
                  type="text"
                  variant="outlined"
                  value={head}
                  onChange={(e) => setHead(e.target.value)}

                  //   className="ww"
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="description"
                  variant="outlined"
                  value={desc}
                  onChange={(e) => setdesc(e.target.value)}
                />
              </div>{" "}
              <div className="inp-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {" "}
                    Direction
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    onChange={(e) => setDirection(e.target.value)}
                    id="demo-simple-select"
                    value={Direction}
                    // value={Chosedur}
                    label={"Direction"}
                    // onChange={(e) => setChosedur(e.target.value)}
                  >
                    <MenuItem value={"left"}>left</MenuItem>
                    <MenuItem value={"right"}>right</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {Isedit ? (
              Loading ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdate}>
                  Edit
                </button>
              )
            ) : Loading ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitAchievements}>
                Add
              </button>
            )}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Year <i className="fa-solid fa-user"></i>
                  </th>
                  <th>
                    Title <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    Description <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th>
                    Direction <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Achivements && Achivements.length >= 0
                  ? Achivements.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.year}</td>
                        <td>{item.head}</td>
                        <td>{item.desc}</td>
                        <td>{item.direction}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => update(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDelete(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      )}

      <ToastContainer position="top-center" />
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDelete}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteA}
        onHide={handleCloseDeleteA}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDeleteA}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDeleteA}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Achievement;
