import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import 'firebase/firestore'
export const firebaseConfig = {
  apiKey:process.env.REACT_APP_API_KEY ,
  authDomain:process.env.REACT_APP_AUTHDOMAIN , 
  projectId: process.env.REACT_APP_PROJECTID ,
  storageBucket:process.env.REACT_APP_STORAGE_BUCKET ,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID ,
  appId:process.env.REACT_APP_APPID ,
  measurementId:process.env.REACT_APP_MEASUERID 
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const storageRef=ref(storage)


