import React, { useState } from "react";
import "./forgetpassword.css";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { Container, Spinner } from "react-bootstrap";
import notify from "../Notifcation";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Forgetpassword = () => {
  const [email, setEmail] = useState("");
  const navigate=useNavigate()
  const [Loading, setLoading] = useState(false);
  const triggerResetEmail = async () => {
    if (email === "") {
        document.getElementById("email").innerHTML = "Email is required";
        return;
      }
      setLoading(true);
    await sendPasswordResetEmail(auth, email);
    setLoading(false);
    notify("Password reset email sent successfuly", "success");
    setTimeout(()=>navigate("/login-skjf5446we5w54f"),2000)
    
  };

  return (
    <div className="login">
      <Container>
        <div className="main-log">
          <div className="login-container">
            <h2>Forget Password</h2>
            <div className="login">
              <input
                autoComplete="off"
                type="email"
                className="form-control"
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <p id="email"></p>
              <button style={{ height: "52px" }} onClick={triggerResetEmail}>
                {Loading?(<Spinner/>):("send")}
              </button>
            </div>
          </div>
        </div>
      </Container>
      <ToastContainer position="top-center" />

    </div>
  );
};

export default Forgetpassword;
