import React from "react";
import "./loader.css";
function Loader() {
  return (
    <>
      <div className="load">
        <div className="three col">
          <div className="loader" id="loader-6">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader;
