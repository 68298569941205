import React, { useEffect, useState } from "react";
import "./intro.css";
import { Container, Spinner, Table } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../../firebase";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import notify from "../../Notifcation";
const Intro = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);
  const [headA, setHeadA] = useState("");
  const [wordA, setwordA] = useState("");
  const [IseditA, setIseditA] = useState(false);
  const [imageA, setImageA] = useState("");
  const [VidA, setVidA] = useState("");
  const [LoadingA, setLoadingA] = useState(null);
  const [LoadingimgA, setLoadingimgA] = useState(null);
  const [IdA, setIdA] = useState(null);
  const [paragraphA, setparagraphA] = useState("");
  const [IntrosA, setIntrosA] = useState([]);

  const [imgorVid, setimgorVid] = useState("");

  const [head, setHead] = useState("");
  const [word, setword] = useState("");
  const [Isedit, setIsedit] = useState(false);
  const [image, setImage] = useState("");
  const [Vid, setVid] = useState("");

  const [Loading, setLoading] = useState(null);
  const [Loadingimg, setLoadingimg] = useState(null);
  const [Id, setId] = useState(null);
  const [paragraph, setparagraph] = useState("");
  const [Intros, setIntros] = useState([]);
  useEffect(
    () =>
      onSnapshot(collection(db, "intro"), (snapshot) =>
        setIntros(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  useEffect(
    () =>
      onSnapshot(collection(db, "introA"), (snapshot) =>
        setIntrosA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 50000000) {
      notify(
        "You cannot upload this video the maximum size of image is 50 mb",
        "warn"
      );
      return;
    } else {
      setLoadingimg(true);
      const storageRef = ref(storage, `image/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
          setLoadingimg(false);
        });
      });
    }
  };
  const handleImageUploadV = (e) => {
    const file = e.target.files[0];
    if (file.size > 50000000) {
      notify(
        "You cannot upload this video the maximum size of image is 50 mb",
        "warn"
      );
      return;
    } else {
      setLoadingimg(true);
      const storageRef = ref(storage, `video/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setVid(downloadURL);
          setLoadingimg(false);
        });
      });
    }
  };
  const handleUpload = () => {
    // Upload the file
    if (head === "" || word === "" || paragraph === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    if (image === "" && Vid === "") {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "intro", Id);
    setLoading(true);
    updateDoc(updateData, {
      title: head,
      video: Vid,
      image: image,
      desc: paragraph,
      word,
    })
      .then(() => {
        setLoading(false);
        notify("Data Updated successfuly", "success");
        setHead("");
        setword("");
        setVid("");
        setImage("");
        setIsedit(false);
        setparagraph("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setHead("");
        setword("");
        setImage("");
        setVid("");
        setId("");
        setIsedit(false);
        setparagraph("");
      });
  };
  const update = (item) => {
    setIsedit(true);
    setId(item.id);
    setparagraph(item.desc);
    setHead(item.title);
    setword(item.word);
    setImage(item.image);
    setVid(item.video);
  };

  const handleImageUploadA = (e) => {
    const file = e.target.files[0];
    if (file.size > 50000000) {
      notify(
        "You cannot upload this video the maximum size of image is 50 mb",
        "warn"
      );
      return;
    } else {
      setLoadingimgA(true);
      const storageRef = ref(storage, `video/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setImageA(downloadURL);
          setLoadingimgA(false);
        });
      });
    }
  };
  const handleImageUploadAV = (e) => {
    const file = e.target.files[0];
    if (file.size > 50000000) {
      notify(
        "You cannot upload this video the maximum size of image is 50 mb",
        "warn"
      );
      return;
    } else {
      setLoadingimgA(true);
      const storageRef = ref(storage, `video/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setVidA(downloadURL);
          setLoadingimgA(false);
        });
      });
    }
  };
  const handleUploadA = () => {
    // Upload the file
    if (headA === "" || wordA === "" || paragraphA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    if (imageA === "" && VidA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "introA", IdA);
    setLoadingA(true);
    updateDoc(updateData, {
      titleA: headA,
      videoA: VidA,
      imageA: imageA,
      descA: paragraphA,
      wordA,
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Updated successfuly", "success");
        setHeadA("");
        setwordA("");
        setVidA("");
        setImageA("");
        setIseditA(false);
        setparagraphA("");
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");
        setHeadA("");
        setwordA("");
        setVidA("");
        setImageA("");
        setIdA("");
        setIseditA(false);
        setparagraphA("");
      });
  };
  const updateA = (item) => {
    setIseditA(true);
    setIdA(item.id);
    setparagraphA(item.descA);
    setHeadA(item.titleA);
    setwordA(item.wordA);
    setImageA(item.imageA);
    setVidA(item.videoA);
  };

  return (
    <>
      <div className="achivement">
        <h1>Intro Slide</h1>
        <div className="cch">
          <div className="cchs">
            اللغة العربية{" "}
            <input
              type="checkbox"
              checked={Arabic}
              onChange={(e) => setArabic(e.target.checked)}
            />
          </div>
          <div className="cchs">
            اللغة الإنجليزية{" "}
            <input
              type="checkbox"
              checked={English}
              onChange={(e) => setEnglish(e.target.checked)}
            />
          </div>
        </div>
        {Arabic ? (
          <>
            {" "}
            <Container>
              <div className="inputs-container">
                <div className="inp-container">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      صورة او لينك
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      onChange={(e) => {
                        setVidA("");
                        setImageA("");
                        setImage("");
                        setVid("");
                        setimgorVid(e.target.value);
                      }}
                      value={imgorVid}
                      // value={Chosedur}
                      label={" صورة او فيديو"}
                      // onChange={(e) => setChosedur(e.target.value)}
                    >
                      <MenuItem value={"img"}>صورة</MenuItem>
                      <MenuItem value={"vid"}>فيديو</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {imgorVid === "img" ? (
                  <div className="inp-container">
                    <TextField
                      type="file"
                      onChange={handleImageUploadA}
                      label={"صورة المقدمة"}
                      focused
                      inputProps={{
                        accept: "image/*",
                      }}
                    />
                    {LoadingimgA ? (
                      <div>
                        <Spinner />
                      </div>
                    ) : null}
                  </div>
                ) : imgorVid === "vid" ? (
                  <div className="inp-container">
                    <TextField
                      type="file"
                      onChange={handleImageUploadAV}
                      label={"فيديو المقدمة"}
                      focused
                      inputProps={{
                        accept: "video/*",
                      }}
                    />
                    {LoadingimgA ? (
                      <div>
                        <Spinner />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="inp-container">
                  <TextField
                    label="العنوان"
                    type="text"
                    variant="outlined"
                    value={headA}
                    onChange={(e) => setHeadA(e.target.value)}
                  />
                </div>
                <div className="inp-container">
                  <TextField
                    label="كلمة"
                    type="text"
                    variant="outlined"
                    value={wordA}
                    onChange={(e) => setwordA(e.target.value)}
                  />
                </div>
                <div className="inp-container">
                  <TextField
                    label="الوصف"
                    variant="outlined"
                    value={paragraphA}
                    onChange={(e) => setparagraphA(e.target.value)}
                  />
                </div>{" "}
                <div className="inp-container"></div>
                <div className="inp-container"></div>
              </div>
              {IseditA ? (
                LoadingA ? (
                  <button className="buttonadd">
                    <Spinner />
                  </button>
                ) : (
                  <button
                    className="buttonadd"
                    onClick={LoadingimgA ? null : handleUploadA}
                  >
                    Edit{" "}
                  </button>
                )
              ) : null}
            </Container>{" "}
            <Container>
              <Table className="maint" responsive striped bordered hover>
                <thead>
                  <tr>
                    {imgorVid === "img" ? (
                      <th>
                        الصورة <i className="fa-solid fa-user"></i>
                      </th>
                    ) : (
                      <th>
                        الفيديو <i className="fa-solid fa-user"></i>
                      </th>
                    )}
                    <th>
                      العنوان <i className="fa-solid fa-layer-group"></i>
                    </th>
                    <th>
                      كلمة <i className="fa-solid fa-layer-group"></i>
                    </th>
                    <th>
                      الوصف <i className="fa-solid fa-file-signature"></i>
                    </th>
                    <th></th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {IntrosA && IntrosA.length >= 0
                    ? IntrosA.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            {item.videoA !== "" ? (
                              <a href={item.videoA}>
                                {" "}
                                <i className="fa-solid fa-image"></i>
                              </a>
                            ) : (
                              <a href={item.imageA}>
                                {" "}
                                <i className="fa-solid fa-image"></i>
                              </a>
                            )}
                          </td>
                          <td>{item.titleA}</td>
                          <td>{item.wordA}</td>
                          <td>{item.descA}</td>
                          <td className="icon-w">
                            <i
                              onClick={() => updateA(item)}
                              className="fa fa-solid fa-pen-to-square"
                              style={{ color: "rgb(0 44 146)" }}
                            ></i>
                          </td>
                          {/* <td className="icon-w">
                       {" "}
                       <i
                              className="fs fa-solid fa-trash-can"
                         style={{ color: "red" }}
                       ></i>
                     </td> */}
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Container>
          </>
        ) : (
          <>
            {" "}
            <Container>
              <div className="inputs-container">
                <div className="inp-container">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      صورة او لينك
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      onChange={(e) => {
                        setVidA("");
                        setImageA("");
                        setImage("");
                        setVid("");
                        setimgorVid(e.target.value);
                      }}
                      value={imgorVid}
                      // value={Chosedur}
                      label={" صورة او فيديو"}
                      // onChange={(e) => setChosedur(e.target.value)}
                    >
                      <MenuItem value={"img"}>صورة</MenuItem>
                      <MenuItem value={"vid"}>فيديو</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {imgorVid === "img" ? (
                  <div className="inp-container">
                    <TextField
                      type="file"
                      onChange={handleImageUpload}
                      label={"صورة المقدمة"}
                      focused
                      inputProps={{
                        accept: "image/*",
                      }}
                    />
                    {LoadingimgA ? (
                      <div>
                        <Spinner />
                      </div>
                    ) : null}
                  </div>
                ) : imgorVid === "img" ? (
                  <div className="inp-container">
                    <TextField
                      type="file"
                      onChange={handleImageUploadV}
                      label={"فيديو المقدمة"}
                      focused
                      inputProps={{
                        accept: "video/*",
                      }}
                    />
                    {LoadingimgA ? (
                      <div>
                        <Spinner />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="inp-container">
                  <TextField
                    label="Title"
                    type="text"
                    variant="outlined"
                    value={head}
                    onChange={(e) => setHead(e.target.value)}
                  />
                </div>
                <div className="inp-container">
                  <TextField
                    label="Word"
                    type="text"
                    variant="outlined"
                    value={word}
                    onChange={(e) => setword(e.target.value)}
                  />
                </div>
                <div className="inp-container">
                  <TextField
                    label="paragraph"
                    variant="outlined"
                    value={paragraph}
                    onChange={(e) => setparagraph(e.target.value)}
                  />
                </div>{" "}
                <div className="inp-container"></div>
                <div className="inp-container"></div>
                <div className="inp-container"></div>
              </div>
              {Isedit ? (
                Loading ? (
                  <button className="buttonadd">
                    <Spinner />
                  </button>
                ) : (
                  <button className="buttonadd" onClick={handleUpload}>
                    Edit{" "}
                  </button>
                )
              ) : null}
            </Container>{" "}
            <Container>
              <Table className="maint" responsive striped bordered hover>
                <thead>
                  <tr>
                    {imgorVid === "img" ? (
                      <th>
                        Image <i className="fa-solid fa-user"></i>
                      </th>
                    ) : (
                      <th>
                        Video <i className="fa-solid fa-user"></i>
                      </th>
                    )}

                    <th>
                      Title <i className="fa-solid fa-layer-group"></i>
                    </th>
                    <th>
                      word <i className="fa-solid fa-layer-group"></i>
                    </th>
                    <th>
                      Description <i className="fa-solid fa-file-signature"></i>
                    </th>
                    <th></th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {Intros && Intros.length >= 0
                    ? Intros.map((item, idx) => (
                        <tr key={idx}>
                          <td>
                            {item.video !== "" ? (
                              <a href={item.video}>
                                {" "}
                                <i className="fa-solid fa-image"></i>
                              </a>
                            ) : (
                              <a href={item.image}>
                                {" "}
                                <i className="fa-solid fa-image"></i>
                              </a>
                            )}
                          </td>
                          <td>{item.title}</td>
                          <td>{item.word}</td>
                          <td>{item.desc}</td>
                          <td className="icon-w">
                            <i
                              onClick={() => update(item)}
                              className="fa fa-solid fa-pen-to-square"
                              style={{ color: "rgb(0 44 146)" }}
                            ></i>
                          </td>
                          {/* <td className="icon-w">
                       {" "}
                       <i
                              className="fs fa-solid fa-trash-can"
                         style={{ color: "red" }}
                       ></i>
                     </td> */}
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Container>
          </>
        )}

        <ToastContainer position="top-center" />
      </div>
    </>
  );
};

export default Intro;
