import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import "./admission.css";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import notify from "../../Notifcation";
import { ToastContainer } from "react-toastify";
const Admission = () => {
  const [Isedit, setIsedit] = useState(false);
  const [Admissions, setAdmissions] = useState([]);
  const [filteredList, setFilteredList] = useState(Admissions);
  const [Loading, setLoading] = useState("");
  const [DateeBirth, setDateeBirth] = useState("");
  const [DivisionName, setDivisionName] = useState("");
  const [AcadimicYear, setAcadimicYear] = useState("");
  const [Name, setName] = useState("");
  const [Level, setLevel] = useState("");
  const [Gender, setGender] = useState("");
  const [Id, setId] = useState("");
  const [Religion, setReligion] = useState("");
  const [Nationality, setNationality] = useState("");
  const [HomeAddress, setHomeAddress] = useState("");
  const [StudenNationalid, setStudenNationalid] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Mothermob, setMothermob] = useState("");
  const [Fathermob, setFathermob] = useState("");
  const [NumberofBS, setNumberofBS] = useState("");
  const [MotherOCC, setMotherOCC] = useState("");
  const [FatherOCC, setFatherOCC] = useState("");
  const [ResidenceWith, setResidenceWith] = useState("");
  const [ShiftedForm, setShiftedForm] = useState("");
  const [Intersbus, setIntersbus] = useState("");
  const tableRef = useRef(null);
  const [arabic, setArabic] = useState("");
  const item = localStorage.getItem("Arabic");
  useEffect(() => {
    setArabic(item);
  }, [item]);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "AdmissionReport",
    sheet: "AdmissionReport",
  });
  const [Acadimicyears, setAcadimicyears] = useState("");
  const [divisionnames, setdivisionnames] = useState("");
  const [Levels, setLevels] = useState("");
  const [nationalitys, setnationalitys] = useState("");
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const ArabicLevel = [
    "تمهيدي حضانة",
    "أولى حضانة",
    "ثانية حضانة  ",
    "أولى إبتدائي  ",
    "ثانية إبتدائي  ",
  ];

  const AmericanLevel = [
    "Pre K",
    "KG 1",
    "KG 2",
    "Grade 1",
    "Grade 2",
    "Grade 3",
  ];

  const NationalLevel = [
    "Pre K",
    "KG 1",
    "KG 2",
    "Primary 1",
    "Primary 2",
    "Primary 3",
  ];
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "divisionname"), orderBy("createdAt", "asc")),
        (snapshot) =>
          setdivisionnames(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "acadimicyear"), orderBy("createdAt", "asc")),
        (snapshot) =>
          setAcadimicyears(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "level"), orderBy("createdAt", "asc")),
        (snapshot) =>
          setLevels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "nationality"), orderBy("createdAt", "asc")),
        (snapshot) =>
          setnationalitys(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          )
      ),
    []
  );
  useEffect(() => {
    setFilteredList(Admissions);
  }, [Admissions]);
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "admission"), orderBy("createdAt", "asc")),
        (snapshot) =>
          setAdmissions(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          )
      ),
    []
  );
  const submitAchievements = async (e) => {
    e.preventDefault();
    if (
      DivisionName === "" ||
      Name === "" ||
      AcadimicYear === "" ||
      Level === "" ||
      DateeBirth === "" ||
      Gender === ""
    ) {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoading(true);
      await addDoc(collection(db, "admission"), {
        Nationality: Nationality,
        Numberofbs: NumberofBS,
        Religion: Religion,
        ResidenceWith: ResidenceWith,
        ShiftedForm: ShiftedForm,
        StudentNationalid: StudenNationalid,
        fatherOccup: FatherOCC,
        mothermob: Mothermob,
        Name: Name,
        MotherOccup: MotherOCC,
        Mobile: Mobile,
        Email: Email,
        Gender: Gender,
        HomeAddress: HomeAddress,
        Interstbus: Intersbus,
        AcademicYear: AcadimicYear,
        BirthDate: DateeBirth,
        DivisionName: DivisionName,
        Fathermob: Fathermob,
        Level: Level,
        createdAt: Timestamp.now(),
      });
      setLoading(false);
      notify("Data sent successfuly", "success");
      setDateeBirth("");
      setDivisionName("");
      setAcadimicYear("");
      setName("");
      setLevel("");
      setGender("");
      setReligion("");
      setNationality("");
      setHomeAddress("");
      setStudenNationalid("");
      setMobile("");
      setEmail("");
      setMothermob("");
      setFathermob("");
      setNumberofBS("");
      setMotherOCC("");
      setFatherOCC("");
      setResidenceWith("");
      setShiftedForm("");
      setIntersbus("");
    } catch (error) {
      notify("Please Try Again", "error");
      setDateeBirth("");
      setDivisionName("");
      setAcadimicYear("");
      setName("");
      setLevel("");
      setGender("");
      setReligion("");
      setNationality("");
      setHomeAddress("");
      setStudenNationalid("");
      setMobile("");
      setEmail("");
      setMothermob("");
      setFathermob("");
      setNumberofBS("");
      setMotherOCC("");
      setFatherOCC("");
      setResidenceWith("");
      setShiftedForm("");
      setIntersbus("");
    }
  };
  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    var updatedList = [...Admissions];
    // Include all elements which includes the search query
    updatedList = updatedList.filter(
      (item) =>
        item.DivisionName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Level.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Mobile.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.AcademicYear.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Nationality.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    // Trigger render with updated values
    setFilteredList(updatedList);
  };

  const update = (item) => {
    setIsedit(true);
    setId(item.id);
    setDateeBirth(item.BirthDate);
    setDivisionName(item.DivisionName);
    setAcadimicYear(item.AcademicYear);
    setName(item.Name);
    setLevel(item.Level);
    setGender(item.Gender);
    setReligion(item.Religion);
    setNationality(item.Nationality);
    setHomeAddress(item.HomeAddress);
    setStudenNationalid(item.StudentNationalid);
    setMobile(item.Mobile);
    setEmail(item.Email);
    setMothermob(item.mothermob);
    setFathermob(item.Fathermob);
    setNumberofBS(item.Numberofbs);
    setMotherOCC(item.MotherOccup);
    setFatherOCC(item.fatherOccup);
    setResidenceWith(item.ResidenceWith);
    setShiftedForm(item.ShiftedForm);
    setIntersbus(item.Interstbus);
  };
  const handleDelete = async () => {
    setLoading(true);
    const deleteVal = doc(db, "admission", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setLoading(false);
    setId("");
    setshowDelete(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (
      DivisionName === "" ||
      Name === "" ||
      AcadimicYear === "" ||
      Level === "" ||
      DateeBirth === "" ||
      Gender === ""
    ) {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "admission", Id);
    await updateDoc(updateData, {
      Nationality: Nationality,
      Numberofbs: NumberofBS,
      Religion: Religion,
      ResidenceWith: ResidenceWith,
      ShiftedForm: ShiftedForm,
      StudentNationalid: StudenNationalid,
      fatherOccup: FatherOCC,
      mothermob: Mothermob,
      Name: Name,
      MotherOccup: MotherOCC,
      Mobile: Mobile,
      Email: Email,
      Gender: Gender,
      HomeAddress: HomeAddress,
      Interstbus: Intersbus,
      AcademicYear: AcadimicYear,
      BirthDate: DateeBirth,
      DivisionName: DivisionName,
      Fathermob: Fathermob,
      Level: Level,
      createdAt: Timestamp.now(),
    });
    notify("Event Edited successfuly", "success");
    setIsedit(false);
    setLoading(false);
    setDateeBirth("");
    setDivisionName("");
    setAcadimicYear("");
    setId("");
    setName("");
    setLevel("");
    setGender("");
    setReligion("");
    setNationality("");
    setHomeAddress("");
    setStudenNationalid("");
    setMobile("");
    setEmail("");
    setMothermob("");
    setFathermob("");
    setNumberofBS("");
    setMotherOCC("");
    setFatherOCC("");
    setResidenceWith("");
    setShiftedForm("");
    setIntersbus("");
  };
  return (
    <div>
      <div className="admission">
        <Container>
          <h1 className="text-center" style={{ fontFamily: "Almarai" }}>
            {arabic === "true" ? "التسجيل" : "Admission"}
          </h1>
          <div className="inputs-container">
            <div className="inp-container">
              <FormControl fullWidth>
                <InputLabel>
                  {" "}
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "اسم القسم" : "Division Name"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                </InputLabel>
                <Select
                  // value={Chosedur}
                  value={DivisionName}
                  onChange={(e) => setDivisionName(e.target.value)}
                  label={
                    <Typography
                      sx={{ fontSize: "18px", fontFamily: "Almarai" }}
                    >
                      {arabic === "true" ? "اسم القسم" : "Division Name"}
                      <span style={{ color: "red", fontSize: "21px" }}>*</span>
                    </Typography>
                  }
                  // onChange={(e) => setChosedur(e.target.value)}
                >
                  {divisionnames && divisionnames.length >= 0
                    ? divisionnames.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div className="inp-container">
              <FormControl fullWidth>
                <InputLabel>
                  {" "}
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "السنة الأكاديمية" : "Academic Year"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                </InputLabel>
                <Select
                  // value={Chosedur}
                  value={AcadimicYear}
                  onChange={(e) => setAcadimicYear(e.target.value)}
                  label={
                    <Typography
                      sx={{ fontSize: "18px", fontFamily: "Almarai" }}
                    >
                      {arabic === "true" ? "السنة الأكاديمية" : "Academic Year"}
                      <span style={{ color: "red", fontSize: "21px" }}>*</span>
                    </Typography>
                  }
                  // onChange={(e) => setChosedur(e.target.value)}
                >
                  {Acadimicyears && Acadimicyears.length >= 0
                    ? Acadimicyears.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div className="inp-container">
              <FormControl fullWidth>
                <InputLabel>
                  {" "}
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "المستوى" : "Level"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                </InputLabel>
                <Select
                  // value={Chosedur}
                  value={Level}
                  onChange={(e) => setLevel(e.target.value)}
                  label={
                    <Typography
                      sx={{ fontSize: "18px", fontFamily: "Almarai" }}
                    >
                      {arabic === "true" ? "المستوى" : "Level"}
                      <span style={{ color: "red", fontSize: "21px" }}>*</span>
                    </Typography>
                  }
                  // onChange={(e) => setChosedur(e.target.value)}
                >
                  {DivisionName === "Arabic"
                    ? ArabicLevel &&
                      ArabicLevel.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))
                    : DivisionName === "American"
                    ? AmericanLevel &&
                      AmericanLevel.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))
                    : DivisionName === "National"
                    ? NationalLevel &&
                      NationalLevel.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div className="inp-container">
              <TextField
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "الأسم" : "Name"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                type="text"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"

                //   className="ww"
              />
            </div>
            <div className="inp-container">
              <FormControl fullWidth>
                <InputLabel>
                  {" "}
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "النوع" : "Gender"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                </InputLabel>
                <Select
                  // value={Chosedur}
                  value={Gender}
                  onChange={(e) => setGender(e.target.value)}
                  label={
                    <Typography
                      sx={{ fontSize: "18px", fontFamily: "Almarai" }}
                    >
                      {arabic === "true" ? "النوع" : "Gender"}
                      <span style={{ color: "red", fontSize: "21px" }}>*</span>
                    </Typography>
                  }
                  // onChange={(e) => setChosedur(e.target.value)}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="inp-container">
              <div className="dateee">{DateeBirth ? DateeBirth : null}</div>
              <i className="ty fa-solid fa-calendar-days"></i>
              <TextField
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "تاريخ الميلاد" : "Birth Date"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                type="date"
                value={DateeBirth}
                onChange={(e) => setDateeBirth(e.target.value)}
              />
            </div>
            <div className="inp-container">
              <FormControl fullWidth>
                <InputLabel>
                  {arabic === "true" ? "الديانة" : "Religion"}
                </InputLabel>
                <Select
                  // value={Chosedur}
                  value={Religion}
                  onChange={(e) => setReligion(e.target.value)}
                  label={arabic === "true" ? "الديانة" : "Religion"}

                  // onChange={(e) => setChosedur(e.target.value)}
                >
                  <MenuItem value={"Muslim"}>Muslim</MenuItem>
                  <MenuItem value={"Christian"}>Christian</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="inp-container">
              <FormControl fullWidth>
                <InputLabel>
                  {" "}
                  {arabic === "true" ? "الجنسية" : "Nationality"}
                </InputLabel>
                <Select
                  // value={Chosedur}
                  value={Nationality}
                  onChange={(e) => setNationality(e.target.value)}
                  label={arabic === "true" ? "الجنسية" : "Nationality"}

                  // onChange={(e) => setChosedur(e.target.value)}
                >
                  {nationalitys && nationalitys.length >= 0
                    ? nationalitys.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "العنوان" : "Home Address"}`}
                value={HomeAddress}
                onChange={(e) => setHomeAddress(e.target.value)}
                variant="outlined"
                type="text"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                value={StudenNationalid}
                onChange={(e) => setStudenNationalid(e.target.value)}
                label={`${
                  arabic === "true" ? "الرقم القومي" : "Student National Id"
                }`}
                variant="outlined"
                type="number"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "الهاتف" : "Mobile"}`}
                value={Mobile}
                onChange={(e) => setMobile(e.target.value)}
                variant="outlined"
                type="number"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "البريد الألكتروني" : "Email"}`}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                type="email"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                value={NumberofBS}
                onChange={(e) => setNumberofBS(e.target.value)}
                label={`${
                  arabic === "true"
                    ? "عدد الاخ او الاخت في المدرسة"
                    : "Number of Brothers/Sisters in School"
                }`}
                variant="outlined"
                type="number"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "الأقامة مع" : "Residence With"}`}
                variant="outlined"
                value={ResidenceWith}
                onChange={(e) => setResidenceWith(e.target.value)}
                type="number"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                value={Fathermob}
                onChange={(e) => setFathermob(e.target.value)}
                label={`${arabic === "true" ? "هاتف الأب" : "Father's Mobile"}`}
                variant="outlined"
                type="number"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "هاتف الأم" : "Mother's Mobile"}`}
                value={Mothermob}
                onChange={(e) => setMothermob(e.target.value)}
                variant="outlined"
                type="number"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true" ? "مهنة الأب" : "Father's Occupation"
                }`}
                value={FatherOCC}
                onChange={(e) => setFatherOCC(e.target.value)}
                variant="outlined"
                type="text"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                value={MotherOCC}
                onChange={(e) => setMotherOCC(e.target.value)}
                label={`${
                  arabic === "true" ? "مهنة الأم" : "Mother's Occupation"
                }`}
                variant="outlined"
                type="text"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "نموذج منقول" : "Shifted Form"}`}
                value={ShiftedForm}
                onChange={(e) => setShiftedForm(e.target.value)}
                variant="outlined"
                type="text"
              />
            </div>{" "}
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true"
                    ? "هل مهتم بالحافلة المدرسية"
                    : "Do you have an interest in school bus"
                }`}
                value={Intersbus}
                onChange={(e) => setIntersbus(e.target.value)}
                variant="outlined"
                type="text"
              />
            </div>{" "}
            <div className="inp-container"></div>
            <div className="inp-container"></div>
          </div>
          {Isedit ? (
            Loading ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={handleUpdate}>
                Edit{" "}
              </button>
            )
          ) : Loading ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={submitAchievements}>
              Add{" "}
            </button>
          )}
        </Container>{" "}
        <ToastContainer position="top-center" />
      </div>
      <Container>
        <div className="inputs-container ">
          <div className="inp-container search ">
            {" "}
            <TextField
              id="outlined-basic"
              label="Search"
              onChange={filterBySearch}
              variant="outlined"
              //   className="ww"
            />
            <i className="ty fa-solid fa-magnifying-glass"></i>
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
      </Container>
      <Container>
        <Table className="maint" responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                Date <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Division Name <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Academic Year <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
                Level <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Name <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Nationality <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Mobile <i className="fa-solid fa-file-signature"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredList && filteredList.length >= 0
              ? filteredList.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      {new Date(
                        item.createdAt.seconds * 1000
                      ).toLocaleDateString("en-GB")}
                    </td>
                    <td>{item.DivisionName}</td>
                    <td>{item.AcademicYear}</td>
                    <td>{item.Level}</td>
                    <td>{item.Name}</td>
                    <td>{item.Nationality}</td>
                    <td>{item.Mobile}</td>
                    <td className="icon-w">
                      <i
                        onClick={() => update(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    <td className="icon-w">
                      {" "}
                      <i
                        onClick={() => handleShoweDelete(item.id)}
                        className="fs fa-solid fa-trash-can"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
        <Table
          ref={tableRef}
          className="maint export"
          responsive
          striped
          bordered
          hover
          style={{display:'none'}}
        >
          <thead>
            <tr>
              <th>
                Date <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Division Name <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Academic Year <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
                Level <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Name <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Gender <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Birth Date <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Religion <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Nationality <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Home Address <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Student National Id{" "}
                <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Mobile <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Email <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Number of Brothers/Sisters in School{" "}
                <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Residence With <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Father's Mobile <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Mother's Mobile <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Father's Occupation{" "}
                <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Mother's Occupation{" "}
                <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Shifted Form
                <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Do You Have an interest in School bus
                <i className="fa-solid fa-file-signature"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredList && filteredList.length >= 0
              ? filteredList.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      {new Date(
                        item.createdAt.seconds * 1000
                      ).toLocaleDateString("en-GB")}
                    </td>
                    <td>{item.DivisionName}</td>
                    <td>{item.AcademicYear}</td>
                    <td>{item.Level}</td>
                    <td>{item.Name}</td>
                    <td>{item.Gender}</td>
                    <td>{item.BirthDate}</td>
                    <td>{item.Religion}</td>
                    <td>{item.Nationality}</td>
                    <td>{item.HomeAddress}</td>
                    <td>{item.StudentNationalid}</td>
                    <td>{item.Mobile}</td>
                    <td>{item.Email}</td>
                    <td>{item.Numberofbs}</td>
                    <td>{item.ResidenceWith}</td>
                    <td>{item.Fathermob}</td>
                    <td>{item.mothermob}</td>
                    <td>{item.fatherOccup}</td>
                    <td>{item.MotherOccup}</td>
                    <td>{item.ShiftedForm}</td>
                    <td>{item.Interstbus}</td>

                    <td className="icon-w">
                      <i
                        onClick={() => update(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    <td className="icon-w">
                      {" "}
                      <i
                        onClick={() => handleShoweDelete(item.id)}
                        className="fs fa-solid fa-trash-can"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </Container>
      <hr />
      <ToastContainer position="top-center" />
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDelete}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <button className="buttonadd" onClick={onDownload}>
        Export
      </button>
    </div>
  );
};

export default Admission;
