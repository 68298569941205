import React, { useEffect, useState } from "react";
import "./facilities.css";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import notify from "../../Notifcation";
import { ToastContainer } from "react-toastify";
const Facilitiesadmin = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);

  const [headA, setHeadA] = useState("");
  const [imageA, setImageA] = useState("");
  const [linkA, setlinkA] = useState("");
  const [checkimgorlinkA, setcheckimgorlinkA] = useState("");
  const [LoadingA, setLoadingA] = useState(null);
  const [IdA, setIdA] = useState(null);
  const [LoadingimgA, setLoadingimgA] = useState(null);
  const [IseditA, setIseditA] = useState(false);
  const [paragraphA, setparagraphA] = useState("");
  const [FacilitiesA, setFacilitiesA] = useState([]);
  const [showDeleteA, setshowDeleteA] = useState(false);
  const [ItemidA, setItemidA] = useState(false);
  const handleCloseDeleteA = () => setshowDeleteA(false);
  const handleShoweDeleteA = (itemid) => {
    setshowDeleteA(true);
    setItemidA(itemid);
  };

  const [head, setHead] = useState("");
  const [image, setImage] = useState("");
  const [link, setlink] = useState("");
  const [checkimgorlink, setcheckimgorlink] = useState("");
  const [Loading, setLoading] = useState(null);
  const [Id, setId] = useState(null);
  const [Loadingimg, setLoadingimg] = useState(null);
  const [Isedit, setIsedit] = useState(false);
  const [paragraph, setparagraph] = useState("");
  const [Facilities, setFacilities] = useState([]);
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  useEffect(
    () =>
      onSnapshot(query(collection(db, "facilities"), orderBy("createdAt", "asc")), (snapshot) =>
        setFacilities(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );

  useEffect(
    () =>
      onSnapshot(query(collection(db, "facilitiesA"), orderBy("createdAt", "asc")), (snapshot) =>
        setFacilitiesA(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      const storageRef = ref(storage, `image/${file.name}`);
      setLoadingimg(true);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          // setImagesrc(downloadURL);
          setImage(downloadURL);
          setLoadingimg(false);
        });
      });
    }
  };
  const handleUpload = () => {
    // Upload the file
    if (head === "" || paragraph === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    setLoading(true);

    addDoc(collection(db, "facilities"), {
      head: head,
      img: image,
      desc: paragraph,
      link: link,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoading(false);
        notify("Data Added successfuly", "success");
        setlink("");
        setcheckimgorlink("");
        setHead("");
        setparagraph("");
        setImage("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setlink("");
        setcheckimgorlink("");
        setHead("");
        setparagraph("");
        setImage("");
      });
  };

  const handleUpdate = () => {
    // Upload the file
    if (head === "" || paragraph === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "facilities", Id);
    setLoading(true);
    updateDoc(updateData, {
      head: head,
      img: image,
      desc: paragraph,
      link: link,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoading(false);
        notify("Data Updated successfuly", "success");
        setHead("");
        setparagraph("");
        setIsedit(false);
        setId("");
        setlink("");
        setcheckimgorlink("");
        setImage("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setHead("");
        setId("");
        setparagraph("");
        setlink("");
        setIsedit(false);
        setcheckimgorlink("");
        setImage("");
      });
  };
  const update = (item) => {
    setcheckimgorlink(item.link !== "" ? "Link" : "img");
    setIsedit(true);
    setId(item.id);
    setparagraph(item.desc);
    setHead(item.head);
    setlink(item.link);
    setImage(item.img);
  };
  const handleDelete = async () => {
    setLoading(true);
    const deleteVal = doc(db, "facilities", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setshowDelete(false);
    setHead("");
    setparagraph("");
    setIsedit(false);
    setlink("");
    setImage("");

    setcheckimgorlink("");
    setLoading(false);
  };

  const handleImageUploadA = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      const storageRef = ref(storage, `image/${file.name}`);
      setLoadingimgA(true);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          // setImagesrc(downloadURL);
          setImageA(downloadURL);
          setLoadingimgA(false);
        });
      });
    }
  };
  const handleUploadA = () => {
    // Upload the file
    if (headA === "" || paragraphA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    setLoadingA(true);

    addDoc(collection(db, "facilitiesA"), {
      headA: headA,
      imgA: imageA,
      descA: paragraphA,
      linkA: linkA,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Added successfuly", "success");
        setlinkA("");
        setcheckimgorlinkA("");
        setHeadA("");
        setparagraphA("");
        setImageA("");
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");
        setlinkA("");
        setcheckimgorlinkA("");
        setHeadA("");
        setparagraphA("");
        setImageA("");
      });
  };

  const handleUpdateA = () => {
    // Upload the file
    if (headA === "" || paragraphA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "facilitiesA", IdA);
    setLoadingA(true);
    updateDoc(updateData, {
      headA: headA,
      imgA: imageA,
      descA: paragraphA,
      linkA: linkA,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Updated successfuly", "success");
        setHeadA("");
        setparagraphA("");
        setIseditA(false);
        setIdA("");
        setlinkA("");
        setcheckimgorlinkA("");
        setImageA("");
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");
        setHeadA("");
        setIdA("");
        setparagraphA("");
        setlinkA("");
        setIseditA(false);
        setcheckimgorlinkA("");
        setImageA("");
      });
  };
  const updateA = (item) => {
    setcheckimgorlinkA(item.linkA !== "" ? "Link" : "img");
    setIseditA(true);
    setIdA(item.id);
    setparagraphA(item.descA);
    setHeadA(item.headA);
    setlinkA(item.linkA);
    setImageA(item.imgA);
  };
  const handleDeleteA = async () => {
    setLoadingA(true);
    const deleteVal = doc(db, "facilitiesA", ItemidA);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setshowDeleteA(false);
    setHeadA("");
    setparagraphA("");
    setIseditA(false);
    setlinkA("");
    setImageA("");

    setcheckimgorlinkA("");
    setLoadingA(false);
  };
  return (
    <div className="achivement">
      <h1>Facilities</h1>
      <div className="cch">
        <div className="cchs">
          اللغة العربية{" "}
          <input
            type="checkbox"
            checked={Arabic}
            onChange={(e) => setArabic(e.target.checked)}
          />
        </div>
        <div className="cchs">
          اللغة الإنجليزية{" "}
          <input
            type="checkbox"
            checked={English}
            onChange={(e) => setEnglish(e.target.checked)}
          />
        </div>
      </div>
      {Arabic ? (
        <>
          {" "}
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    صورة او لينك
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    onChange={(e) => setcheckimgorlinkA(e.target.value)}
                    value={checkimgorlinkA}
                    // value={Chosedur}
                    label={" صورة او رابط"}
                    // onChange={(e) => setChosedur(e.target.value)}
                  >
                    <MenuItem value={"Link"}>رابط</MenuItem>
                    <MenuItem value={"img"}>صورة</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {checkimgorlinkA === "img" ? (
                <div className="inp-container">
                  <TextField
                    id="outlined-basic"
                    type="file"
                    focused
                    label="Image"
                    inputProps={{
                      accept: "image/*",
                    }}
                    onChange={handleImageUploadA}
                  />
                  {LoadingimgA ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : null}
                </div>
              ) : checkimgorlinkA === "Link" ? (
                <div className="inp-container">
                  <TextField
                    id="outlined-basic"
                    label="الرابط"
                    type="text"
                    variant="outlined"
                    focused
                    value={linkA}
                    onChange={(e) => setlinkA(e.target.value)}
                  />
                </div>
              ) : null}
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="العنوان"
                  type="text"
                  variant="outlined"
                  value={headA}
                  onChange={(e) => setHeadA(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="الوصف"
                  variant="outlined"
                  value={paragraphA}
                  onChange={(e) => setparagraphA(e.target.value)}
                />
              </div>{" "}
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {IseditA ? (
              LoadingA ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdateA}>
                  Edit{" "}
                </button>
              )
            ) : LoadingA ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={handleUploadA}>
                Add{" "}
              </button>
            )}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    الصورة <i className="fa-solid fa-user"></i>
                  </th>
                  <th>
                    العنوان <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    الوصف <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th>
                    الرابط <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {FacilitiesA && FacilitiesA.length >= 0
                  ? FacilitiesA.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <a href={item.imgA}>
                            {" "}
                            <i className="fa-solid fa-image"></i>
                          </a>
                        </td>

                        <td>{item.headA}</td>
                        <td>{item.descA}</td>
                        <td>{item.linkA}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => updateA(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDeleteA(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      ) : (
        <>
          {" "}
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Image or Link
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    onChange={(e) => setcheckimgorlink(e.target.value)}
                    value={checkimgorlink}
                    // value={Chosedur}
                    label={"Image or Link"}
                    // onChange={(e) => setChosedur(e.target.value)}
                  >
                    <MenuItem value={"Link"}>Link</MenuItem>
                    <MenuItem value={"img"}>Image</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {checkimgorlink === "img" ? (
                <div className="inp-container">
                  <TextField
                    id="outlined-basic"
                    type="file"
                    focused
                    label="Image"
                    inputProps={{
                      accept: "image/*",
                    }}
                    onChange={handleImageUpload}
                  />
                  {Loadingimg ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : null}
                </div>
              ) : checkimgorlink === "Link" ? (
                <div className="inp-container">
                  <TextField
                    id="outlined-basic"
                    label="Link"
                    type="text"
                    variant="outlined"
                    focused
                    value={link}
                    onChange={(e) => setlink(e.target.value)}
                  />
                </div>
              ) : null}
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="Title"
                  type="text"
                  variant="outlined"
                  value={head}
                  onChange={(e) => setHead(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="paragraph"
                  variant="outlined"
                  value={paragraph}
                  onChange={(e) => setparagraph(e.target.value)}
                />
              </div>{" "}
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {Isedit ? (
              Loading ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdate}>
                  Edit{" "}
                </button>
              )
            ) : Loading ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={handleUpload}>
                Add{" "}
              </button>
            )}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Image <i className="fa-solid fa-user"></i>
                  </th>
                  <th>
                    Title <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    Description <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th>
                    Link <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Facilities && Facilities.length >= 0
                  ? Facilities.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <a href={item.img}>
                            {" "}
                            <i className="fa-solid fa-image"></i>
                          </a>
                        </td>

                        <td>{item.head}</td>
                        <td>{item.desc}</td>
                        <td>{item.link}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => update(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDelete(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      )}

      <ToastContainer position="top-center" />
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDelete}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteA}
        onHide={handleCloseDeleteA}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDeleteA}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDeleteA}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Facilitiesadmin;
