import React from "react";
import Up from "../up/up";
import Slider from "../slider/slider";
import Card from "../card/card";
import Timeline from "../timeline/timeline";
import Featuer from "../featuer/featuer";
import Infoappl from "../left/infoapp";
import Conatct from "../conatct/conatct";
import YouetubeEvent from "../events/events";
const Homepage = ({Arabic}) => {
  
  return (
    <>
      {" "}
      <Up />
      <Slider Arabic={Arabic} />
      <Card Arabic={Arabic} />
      <YouetubeEvent link="/activites"  Arabic={Arabic}/>
      <Featuer Arabic={Arabic} />
      <Timeline Arabic={Arabic}/>
      <Infoappl Arabic={Arabic} />
      <Conatct Arabic={Arabic}/>
    </>
  );
};

export default Homepage;
