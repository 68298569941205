import React, { useEffect, useState } from "react";
import "./infoapp.css";
import { Fade } from "react-awesome-reveal";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
const Infoappl = ({ title, desc, link, Arabic }) => {
  const [Abouts, setAbouts] = useState([]);
  const [AboutsA, setAboutsA] = useState([]);

  useEffect(
    () =>
      onSnapshot(collection(db, "about"), (snapshot) =>
        setAbouts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  
  useEffect(
    () =>
      onSnapshot(collection(db, "aboutA"), (snapshot) =>
        setAboutsA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  return (
    <div>
      <section className="about">
        <Link to={"/aboutuspage"} style={{ textDecoration: "none" }}>
          <h3 className="tit">
            {Arabic === "true" ? "معلومات عنا" : "About Us"}
          </h3>
        </Link>
        {Arabic === "true" ? (<>
          <div className="container">
          {AboutsA && AboutsA.length >= 0
            ? AboutsA.map((item, idx) => (
                <div className="row" key={idx}>
                  <div className="col-md-6 ">
                    <Fade direction="left" triggerOnce="false">
                      <h2 className="text-uppercase">{item.headA}</h2>
                      <p>{item.descA}</p>
                    </Fade>
                  </div>
                  <div className="col-md-6">
                    <Fade direction="right" triggerOnce="false">
                      <img
                        src={item.iconA}
                        className="img-responsive"
                        alt="feature img"
                      />
                    </Fade>
                  </div>
                </div>
              ))
            : null}
                 <Link style={{width:"80px",textDecoration:"none"}} className="buttonadd" to={'/aboutuspage'} >
                    المزيد
                  </Link>
        </div></>) : (<>
          <div className="container">
          {Abouts && Abouts.length >= 0
            ? Abouts.map((item, idx) => (
                <div className="row" key={idx}>
                  <div className="col-md-6 ">
                    <Fade direction="left" triggerOnce="false">
                      <h2 className="text-uppercase">{item.head}</h2>
                      <p>{item.desc}</p>
                    </Fade>
                  </div>
                  <div className="col-md-6">
                    <Fade direction="right" triggerOnce="false">
                      <img
                        src={item.icon}
                        className="img-responsive"
                        alt="feature img"
                      />
                    </Fade>
                  </div>
                </div>
              ))
            : null}
                 <Link style={{width:"80px",textDecoration:"none"}} className="buttonadd" to={'/aboutuspage'} >
                    More
                  </Link>
        </div></>)}
      
      </section>
    </div>
  );
};

export default Infoappl;
