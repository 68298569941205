import React, { useEffect, useState } from "react";
import "./featuer.css";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import { TextField } from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import notify from "../../Notifcation";
import { ToastContainer } from "react-toastify";
const Featuer = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);

  const [headA, setHeadA] = useState("");
  const [imageA, setImageA] = useState("");
  const [LoadingA, setLoadingA] = useState(null);
  const [IdA, setIdA] = useState(null);
  const [LoadingimgA, setLoadingimgA] = useState(null);
  const [IseditA, setIseditA] = useState(false);
  const [paragraphA, setparagraphA] = useState("");
  const [FeatureA, setFeatureA] = useState([]); 
  const [showDeleteA, setshowDeleteA] = useState(false);
  const [ItemidA, setItemidA] = useState(false);
  const handleCloseDeleteA = () => setshowDeleteA(false);
  const handleShoweDeleteA = (itemid) => {
    setshowDeleteA(true);
    setItemidA(itemid);
  };

  const [head, setHead] = useState("");
  const [image, setImage] = useState("");
  const [Loading, setLoading] = useState(null);
  const [Id, setId] = useState(null);
  const [Loadingimg, setLoadingimg] = useState(null);
  const [Isedit, setIsedit] = useState(false);
  const [paragraph, setparagraph] = useState("");
  const [Feature, setFeature] = useState([]);
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  useEffect(
    () =>
      onSnapshot(query(collection(db, "feature"), orderBy("createdAt", "asc")), (snapshot) =>
        setFeature(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  useEffect(
    () =>
      onSnapshot(query(collection(db, "featureA"), orderBy("createdAt", "asc")), (snapshot) =>
        setFeatureA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    }
    else{
    const storageRef = ref(storage, `image/${file.name}`);
    setLoadingimg(true);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        // setImagesrc(downloadURL);
        setImage(downloadURL);
        setLoadingimg(false);
      });
    });
  }
  };
  const handleUpload = () => {
    // Upload the file
    if (head === "" || paragraph === "" || image === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    if(Feature.length>=6){
      notify("You cannot add more than 6 items", "warn");
      return;
    }
    setLoading(true);

    addDoc(collection(db, "feature"), {
      head: head,
      icon: image,
      desc: paragraph,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoading(false);
        notify("Data Added successfuly", "success");

        setHead("");
        setparagraph("");
        setImage("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");

        setHead("");
        setparagraph("");
        setImage("");
      });
  };

  const handleUpdate = () => {
    // Upload the file
    if (head === "" || paragraph === "" || image === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "feature", Id);
    setLoading(true);
    updateDoc(updateData, {
      head: head,
      icon: image,
      desc: paragraph,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoading(false);
        notify("Data Updated successfuly", "success");
        setHead("");
        setparagraph("");
        setIsedit(false)
        setId("")
        setImage('');
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setHead("");
        setId("")
        setparagraph("");
        setIsedit(false)

        setImage('');
      });
  };
  const update = (item) => {
    setIsedit(true);
    setId(item.id);
    setparagraph(item.desc);
    setHead(item.head);
    setImage(item.icon);
  };
  const handleDelete = async () => {
    setLoading(true);
    const deleteVal = doc(db, "feature", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setshowDelete(false);
    setHead("");
    setparagraph("");
    setIsedit(false)

    setImage('');

    setLoading(false);
  };

  const handleImageUploadA = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    }
    else{
    const storageRef = ref(storage, `image/${file.name}`);
    setLoadingimgA(true);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        // setImagesrc(downloadURL);
        setImageA(downloadURL);
        setLoadingimgA(false);
      });
    });
  }
  };
  const handleUploadA = () => {
    // Upload the file
    if (headA === "" || paragraphA === "" || imageA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    if(FeatureA.length>=6){
      notify("You cannot add more than 6 items", "warn");
      return;
    }
    setLoadingA(true);

    addDoc(collection(db, "featureA"), {
      headA: headA,
      iconA: imageA,
      descA: paragraphA,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Added successfuly", "success");

        setHeadA("");
        setparagraphA("");
        setImageA("");
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");

        setHeadA("");
        setparagraphA("");
        setImageA("");
      });
  };

  const handleUpdateA = () => {
    // Upload the file
    if (headA === "" || paragraphA === "" || imageA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "featureA", IdA);
    setLoadingA(true);
    updateDoc(updateData, {
      headA: headA,
      iconA: imageA,
      descA: paragraphA,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Updated successfuly", "success");
        setHeadA("");
        setparagraphA("");
        setIseditA(false)
        setIdA("")
        setImageA('');
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");
        setHeadA("");
        setIdA("")
        setparagraphA("");
        setIseditA(false)

        setImageA('');
      });
  };
  const updateA = (item) => {
    setIseditA(true);
    setIdA(item.id);
    setparagraphA(item.descA);
    setHeadA(item.headA);
    setImageA(item.iconA);
  };
  const handleDeleteA = async () => {
    setLoadingA(true);
    const deleteVal = doc(db, "featureA", ItemidA);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setshowDeleteA(false);
    setHeadA("");
    setparagraphA("");
    setIseditA(false)

    setImageA('');

    setLoadingA(false);
  };
  return (
    <div className="achivement">
        <h1>OUR VALUES</h1>
        <div className="cch">
          <div className="cchs">
            اللغة العربية{" "}
            <input
              type="checkbox"
              checked={Arabic}
              onChange={(e) => setArabic(e.target.checked)}
            />
          </div>
          <div className="cchs">
            اللغة الإنجليزية{" "}
            <input
              type="checkbox"
              checked={English}
              onChange={(e) => setEnglish(e.target.checked)}
            />
          </div>
        </div>
        {Arabic?(<>
          <Container>
        <div className="inputs-container">
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              type="file"
              inputProps={{
                accept: 'image/*',
              }}
              focused
              label="الصورة"
              onChange={handleImageUploadA}
            />
            {LoadingimgA ? (
              <div>
                <Spinner />
              </div>
            ) : null}
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="العنوان"
              type="text"
              variant="outlined"
              value={headA}
              onChange={(e) => setHeadA(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="الوصف"
              variant="outlined"
              value={paragraphA}
              onChange={(e) => setparagraphA(e.target.value)}
            />
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
        {IseditA ? (
          LoadingA ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={handleUpdateA}>
              Edit{" "}
            </button>
          )
        ) : LoadingA ? (
          <button className="buttonadd">
            <Spinner />
          </button>
        ) : (
          <button className="buttonadd" onClick={handleUploadA}>
            Add{" "}
          </button>
        )}
      </Container>{" "}
      <Container>
        <Table className="maint" responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                الصور <i className="fa-solid fa-user"></i>
              </th>
              <th>
                العنوان <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
              الوصف <i className="fa-solid fa-file-signature"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {FeatureA && FeatureA.length >= 0
              ? FeatureA.map((item,idx) => (
                  <tr key={idx} >
                    <td>
                      <a href={item.iconA}>
                        {" "}
                        <i className="fa-solid fa-image"></i>
                      </a>
                    </td>
                    <td>{item.headA}</td>
                    <td>{item.descA}</td>
                    <td className="icon-w">
                      <i
                        onClick={() => updateA(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    <td className="icon-w">
                      {" "}
                      <i
                        onClick={() => handleShoweDeleteA(item.id)}
                        className="fs fa-solid fa-trash-can"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </Container></>):(<>
          <Container>
      
        <div className="inputs-container">
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              type="file"
              inputProps={{
                accept: 'image/*',
              }}
              focused
              label="Image"
              onChange={handleImageUpload}
            />
            {Loadingimg ? (
              <div>
                <Spinner />
              </div>
            ) : null}
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="Title"
              type="text"
              variant="outlined"
              value={head}
              onChange={(e) => setHead(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="paragraph"
              variant="outlined"
              value={paragraph}
              onChange={(e) => setparagraph(e.target.value)}
            />
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
        {Isedit ? (
          Loading ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={handleUpdate}>
              Edit{" "}
            </button>
          )
        ) : Loading ? (
          <button className="buttonadd">
            <Spinner />
          </button>
        ) : (
          <button className="buttonadd" onClick={handleUpload}>
            Add{" "}
          </button>
        )}
      </Container>{" "}
      <Container>
        <Table className="maint" responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                Image <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Title <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
                Description <i className="fa-solid fa-file-signature"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Feature && Feature.length >= 0
              ? Feature.map((item,idx) => (
                  <tr key={idx} >
                    <td>
                      <a href={item.icon}>
                        {" "}
                        <i className="fa-solid fa-image"></i>
                      </a>
                    </td>
                    <td>{item.head}</td>
                    <td>{item.desc}</td>
                    <td className="icon-w">
                      <i
                        onClick={() => update(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    <td className="icon-w">
                      {" "}
                      <i
                        onClick={() => handleShoweDelete(item.id)}
                        className="fs fa-solid fa-trash-can"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </Container></>)}
    
      <ToastContainer position="top-center" />
      <Modal
          show={showDelete}
          onHide={handleCloseDelete}
          backdrop="static"
          centered
          contentClassName="cont"
          keyboard={false}
          style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
        >
          {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
          <Modal.Body>Do you want to delete this Item ?</Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              className="close"
              onClick={handleCloseDelete}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="edit"
              onClick={handleDelete}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showDeleteA}
          onHide={handleCloseDeleteA}
          backdrop="static"
          centered
          contentClassName="cont"
          keyboard={false}
          style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
        >
          {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
          <Modal.Body>Do you want to delete this Item ?</Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              className="close"
              onClick={handleCloseDeleteA}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="edit"
              onClick={handleDeleteA}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
};
export default Featuer;
