import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import "./admission.css";
import notify from "../Notifcation";
import { Timestamp, addDoc, collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { ToastContainer } from "react-toastify";
const AdmissionREG = ({Arabic}) => {
  const [DateeBirth, setDateeBirth] = useState("");
  const [DivisionName, setDivisionName] = useState("");
  const [AcadimicYear, setAcadimicYear] = useState("");
  const [Name, setName] = useState("");
  const [Level, setLevel] = useState("");
  const [Gender, setGender] = useState("");
  const [Religion, setReligion] = useState("");
  const [Nationality, setNationality] = useState("");
  const [HomeAddress, setHomeAddress] = useState("");
  const [StudenNationalid, setStudenNationalid] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Mothermob, setMothermob] = useState("");
  const [Fathermob, setFathermob] = useState("");
  const [NumberofBS, setNumberofBS] = useState("");
  const [MotherOCC, setMotherOCC] = useState("");
  const [FatherOCC, setFatherOCC] = useState("");
  const [ResidenceWith, setResidenceWith] = useState("");
  const [ShiftedForm, setShiftedForm] = useState("");
  const [Intersbus, setIntersbus] = useState("");
  const [Loading, setLoading] = useState("");
  const [Acadimicyears, setAcadimicyears] = useState("");
  const [divisionnames, setdivisionnames] = useState("");
  const [Levels, setLevels] = useState("");
  const [nationalitys, setnationalitys] = useState("");

  const ArabicLevel=['تمهيدي حضانة','أولى حضانة','ثانية حضانة  ','أولى إبتدائي  ','ثانية إبتدائي  ']

  const AmericanLevel=['Pre K','KG 1','KG 2','Grade 1','Grade 2','Grade 3']

  const NationalLevel=['Pre K','KG 1','KG 2','Primary 1','Primary 2','Primary 3']

  useEffect(
    () =>
      onSnapshot(query(collection(db, "divisionname"), orderBy("createdAt", "asc")), (snapshot) =>
        setdivisionnames(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "acadimicyear"), orderBy("createdAt", "asc")), (snapshot) =>
        setAcadimicyears(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "level"), orderBy("createdAt", "asc")), (snapshot) =>
        setLevels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "nationality"), orderBy("createdAt", "asc")), (snapshot) =>
        setnationalitys(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  const submitadmission = async (e) => {
    e.preventDefault();
    if (
      DivisionName === "" ||
      Name === "" ||
      AcadimicYear === "" ||
      Level === "" ||
      DateeBirth === "" ||
      Gender === "" 

    ) {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoading(true);
      await addDoc(collection(db, "admission"), {
        Nationality: Nationality,
        Numberofbs: NumberofBS,
        Religion: Religion,
        ResidenceWith: ResidenceWith,
        ShiftedForm: ShiftedForm,
        StudentNationalid: StudenNationalid,
        fatherOccup: FatherOCC,
        mothermob: Mothermob,
        Name: Name,
        MotherOccup: MotherOCC,
        Mobile: Mobile,
        Email: Email,
        Gender: Gender,
        HomeAddress: HomeAddress,
        Interstbus: Intersbus,
        AcademicYear: AcadimicYear,
        BirthDate: DateeBirth,
        DivisionName: DivisionName,
        Fathermob: Fathermob,
        Level: Level,
        createdAt: Timestamp.now(),
      });
      
      setLoading(false);
      notify("Data sent successfuly", "success");
      setDateeBirth("");
      setDivisionName("");
      setAcadimicYear("");
      setName("");
      setLevel("");
      setGender("");
      setReligion("");
      setNationality("");
      setHomeAddress("");
      setStudenNationalid("");
      setMobile("");
      setEmail("");
      setMothermob("");
      setFathermob("");
      setNumberofBS("");
      setMotherOCC("");
      setFatherOCC("");
      setResidenceWith("");
      setShiftedForm("");
      setIntersbus("");
    } catch (error) {
      notify("Please Try Again", "error");
      setDateeBirth("");
      setDivisionName("");
      setAcadimicYear("");
      setName("");
      setLevel("");
      setGender("");
      setReligion("");
      setNationality("");
      setHomeAddress("");
      setStudenNationalid("");
      setMobile("");
      setEmail("");
      setMothermob("");
      setFathermob("");
      setNumberofBS("");
      setMotherOCC("");
      setFatherOCC("");
      setResidenceWith("");
      setShiftedForm("");
      setIntersbus("");
    }
  };
  return (
    <div className="admission">
      <Container>
        <h1 className="tit text-center"style={{fontFamily:"Almarai"}}>
          {Arabic === "true" ? "تسجيل الطلاب" : "Admission"}{" "}
        </h1>
        <div className="inputs-container">
          <div className="inp-container">
            <FormControl fullWidth>
              <InputLabel>
                {" "}
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "اسم القسم" : "Division Name"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              </InputLabel>
              <Select
                // value={Chosedur}
                value={DivisionName}
                onChange={(e) => setDivisionName(e.target.value)}
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {Arabic === "true" ? "اسم القسم" : "Division Name"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                // onChange={(e) => setChosedur(e.target.value)}
              >
                {divisionnames && divisionnames.length >= 0
                  ? divisionnames.map((item,idx) => (
                      <MenuItem key={idx} value={item.name}>{item.name}</MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </div>
          <div className="inp-container">
            <FormControl fullWidth>
              <InputLabel>
                {" "}
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "السنة الأكاديمية" : "Academic Year"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              </InputLabel>
              <Select
                // value={Chosedur}
                value={AcadimicYear}
                onChange={(e) => setAcadimicYear(e.target.value)}
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {Arabic === "true" ? "السنة الأكاديمية" : "Academic Year"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                // onChange={(e) => setChosedur(e.target.value)}
              >
                {Acadimicyears && Acadimicyears.length >= 0
                  ? Acadimicyears.map((item,idx) => (
                      <MenuItem key={idx} value={item.name}>{item.name}</MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </div>
          <div className="inp-container">
            <FormControl fullWidth>
              <InputLabel>
                {" "}
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "المستوى" : "Level"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              </InputLabel>
              <Select
                // value={Chosedur}
                value={Level}
                onChange={(e) => setLevel(e.target.value)}
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {Arabic === "true" ? "المستوى" : "Level"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                // onChange={(e) => setChosedur(e.target.value)}
              >
                {DivisionName === "Arabic" ? (
                  ArabicLevel&&ArabicLevel.map((item)=>(
                    <MenuItem value={item}>{item}</MenuItem>

                  ))

                ) : DivisionName === "American" ? (
                  AmericanLevel&&AmericanLevel.map((item)=>(
                    <MenuItem value={item}>{item}</MenuItem>

                  ))
                ) : DivisionName === "National" ? (
                  NationalLevel&&NationalLevel.map((item)=>(
                    <MenuItem value={item}>{item}</MenuItem>

                  ))
                ) : null}
              </Select>
            </FormControl>
          </div>
          <div className="inp-container">
            <TextField
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "الأسم" : "Name"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              type="text"
              value={Name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"

              //   className="ww"
            />
          </div>
          <div className="inp-container">
            <FormControl fullWidth>
              <InputLabel>
                {" "}
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "النوع" : "Gender"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              </InputLabel>
              <Select
                // value={Chosedur}
                value={Gender}
                onChange={(e) => setGender(e.target.value)}
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {Arabic === "true" ? "النوع" : "Gender"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                // onChange={(e) => setChosedur(e.target.value)}
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="inp-container">
            <div className="dateee">{DateeBirth ? DateeBirth : null}</div>
            <i className="ty fa-solid fa-calendar-days"></i>
            <TextField
              label={
                <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                  {Arabic === "true" ? "تاريخ الميلاد" : "Birth Date"}
                  <span style={{ color: "red", fontSize: "21px" }}>*</span>
                </Typography>
              }
              variant="outlined"
              type="date"
              value={DateeBirth}
              onChange={(e) => setDateeBirth(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <FormControl fullWidth>
              <InputLabel>
                {" "}
        
                  {Arabic === "true" ? "الديانة" : "Religion"}
        
              </InputLabel>
              <Select
                // value={Chosedur}
                value={Religion}
                onChange={(e) => setReligion(e.target.value)}
                label=
                  
                    {Arabic === "true" ? "الديانة" : "Religion"}
          
                
                // onChange={(e) => setChosedur(e.target.value)}
              >
                <MenuItem value={"Muslim"}>Muslim</MenuItem>
                <MenuItem value={"Christian"}>Christian</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="inp-container">
            <FormControl fullWidth>
              <InputLabel>
                {" "}
                  {Arabic === "true" ? "الجنسية" : "Nationality"}
         
              </InputLabel>
              <Select
                // value={Chosedur}
                value={Nationality}
                onChange={(e) => setNationality(e.target.value)}
                label=
                    {Arabic === "true" ? "الجنسية" : "Nationality"}
         
                
                // onChange={(e) => setChosedur(e.target.value)}
              >
                {nationalitys && nationalitys.length >= 0
                  ? nationalitys.map((item,idx) => (
                      <MenuItem key={idx} value={item.name}>{item.name}</MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </div>
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "العنوان" : "Home Address"}`}
              value={HomeAddress}
              onChange={(e) => setHomeAddress(e.target.value)}
              variant="outlined"
              type="text"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              value={StudenNationalid}
              onChange={(e) => setStudenNationalid(e.target.value)}
              label={`${
                Arabic === "true" ? "الرقم القومي" : "Student National Id"
              }`}
              variant="outlined"
              type="number"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "الهاتف" : "Mobile"}`}
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
              variant="outlined"
              type="number"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "البريد الألكتروني" : "Email"}`}
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              type="email"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              value={NumberofBS}
              onChange={(e) => setNumberofBS(e.target.value)}
              label={`${
                Arabic === "true"
                  ? "عدد الاخ او الاخت في المدرسة"
                  : "Number of Brothers/Sisters in School"
              }`}
              variant="outlined"
              type="number"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "الأقامة مع" : "Residence With"}`}
              variant="outlined"
              value={ResidenceWith}
              onChange={(e) => setResidenceWith(e.target.value)}
              type="number"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              value={Fathermob}
              onChange={(e) => setFathermob(e.target.value)}
              label={`${Arabic === "true" ? "هاتف الأب" : "Father's Mobile"}`}
              variant="outlined"
              type="number"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "هاتف الأم" : "Mother's Mobile"}`}
              value={Mothermob}
              onChange={(e) => setMothermob(e.target.value)}
              variant="outlined"
              type="number"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "مهنة الأب" : "Father's Occupation"}`}
              value={FatherOCC}
              onChange={(e) => setFatherOCC(e.target.value)}
              variant="outlined"
              type="text"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              value={MotherOCC}
              onChange={(e) => setMotherOCC(e.target.value)}
              label={`${Arabic === "true" ? "مهنة الأم" : "Mother's Occupation"}`}
              variant="outlined"
              type="text"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              label={`${Arabic === "true" ? "نموذج منقول" : "Shifted Form"}`}
              value={ShiftedForm}
              onChange={(e) => setShiftedForm(e.target.value)}
              variant="outlined"
              type="text"
            />
          </div>{" "}
          <div className="inp-container">
            <TextField
              label={`${
                Arabic === "true"
                  ? "هل مهتم بالحافلة المدرسية"
                  : "Do you have an interest in school bus"
              }`}
              value={Intersbus}
              onChange={(e) => setIntersbus(e.target.value)}
              variant="outlined"
              type="text"
            />
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
        {Loading ? (
          <button className="buttonadd">
            <Spinner />
          </button>
        ) : (
          <button style={{fontFamily:"Almarai"}} className="buttonadd" onClick={submitadmission}>
            {Arabic==="true" ?"إرسال":"Send"}
          </button>
        )}
      </Container>{" "}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default AdmissionREG;
