import React, { useEffect, useState } from "react";
import "./calenderarabic.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
const CalenderclientsArabic = ({Arabic}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  // const [eventName, setEventName] = useState("");
  const [events, setEvents] = useState([]);
  const Date_Click_Fun = (date) => {
    setSelectedDate(date);
  };

  // const Event_Data_Update = (event) => {
  //   setEventName(event.target.value);
  // };
  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    const querySnapshot = await getDocs(collection(db, "calenderarabic"));
    setEvents(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  // const submitEvent = async (e) => {
  //   e.preventDefault();
  //   if (selectedDate && eventName) {
  //     try {
  //       await addDoc(collection(db, "calenderarabic"), {
  //         date: selectedDate.toDateString(),
  //         eventname: eventName,
  //       });
  //       await getEvent();
  //     } catch (error) {
  //       alert("Failed to add event");
  //     }
  //   }
  // };
  return (
    <div className="calenderr rrs ara">
      <h2 className="tit text-center"style={{color:"#008F5E"}}>Welcome To Arabic School Calender</h2>
      <div className="container">
        <div className="calendar-container">
          <Calendar
            value={selectedDate}
            onClickDay={Date_Click_Fun}
            tileClassName={({ date }) =>
              selectedDate &&
              date.toDateString() === selectedDate.toDateString()
                ? "selected"
                : events.some((event) => event.date === date.toDateString())
                ? "event-marked"
                : ""
            }
          />{" "}
        </div>
        <div className="event-container">
          {events.length >= 0 && selectedDate && (
            <div className="event-list">
              <h2 style={{fontSize:'23px'}}>  {Arabic === "true"?('أحداث المدرسة'):('Events List')} </h2>{" "}
              <div className="event-cards">
                {" "}
                {events.map((event) =>
                  event.date === selectedDate.toDateString() ? (
                    <div key={event.id} className="event-card">
                      <div className="event-card-header">
                        <span className="event-date"> {event.date} </span>{" "}
                      </div>{" "}
                      <div className="event-card-body">
                        <p className="event-title"> {event.eventname} </p>{" "}
                      </div>{" "}
                    </div>
                  ) : null
                )}{" "}
              </div>{" "}
            </div>
          )}{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default CalenderclientsArabic;
