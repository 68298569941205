import React, { useEffect, useState } from "react";
import "./blog.css";
import { storage, db } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import { TextField } from "@mui/material";
import { ToastContainer } from "react-toastify";
import notify from "../../Notifcation";
const Blog = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);

  const [headA, setHeadA] = useState("");
  const [imageA, setImageA] = useState("");
  const [LoadingA, setLoadingA] = useState(null);
  const [LoadingimgA, setLoadingimgA] = useState(null);
  const [IdA, setIdA] = useState(null);
  const [IseditA, setIseditA] = useState(false);
  const [paragraphA, setparagraphA] = useState("");
  const [BlogsA, setBlogsA] = useState([]);
  const [showDeleteA, setshowDeleteA] = useState(false);
  const [ItemidA, setItemidA] = useState(false);
  const handleCloseDeleteA = () => setshowDelete(false);
  const handleShoweDeleteA = (itemid) => {
    setshowDeleteA(true);
    setItemidA(itemid);
  };

  const [head, setHead] = useState("");
  const [image, setImage] = useState("");
  const [Loading, setLoading] = useState(null);
  const [Loadingimg, setLoadingimg] = useState(null);
  const [Id, setId] = useState(null);
  const [Isedit, setIsedit] = useState(false);
  const [paragraph, setparagraph] = useState("");
  const [Blogs, setBlogs] = useState([]);
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  useEffect(
    () =>
      onSnapshot(query(collection(db, "blog"), orderBy("createdAt", "asc")), (snapshot) =>
        setBlogs(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "blogA"), orderBy("createdAt", "asc")), (snapshot) =>
        setBlogsA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      const storageRef = ref(storage, `image/${file.name}`);
      setLoadingimg(true);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
          setLoadingimg(false);
        });
      });
    }
  };
  const handleUpload = () => {
    // Upload the file
    if (head === "" || paragraph === "" || image === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    if (Blogs.length >= 50) {
      notify("You cannot add more than 50 items", "warn");
      return;
    }
    setLoading(true);

    addDoc(collection(db, "blog"), {
      title: head,
      img: image,
      desc: paragraph,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        notify("Data Added successfuly", "success");
        setLoading(false);
        setHead("");
        setImage("");
        setparagraph("");
      })
      .catch((error) => {
        notify("Please Try Again", "error");
        setLoading(false);
        setHead("");
        setImage("");
        setparagraph("");
      });
  };

  const handleUpdate = () => {
    // Upload the file
    if (head === "" || paragraph === "" || image === "") {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "blog", Id);
    setLoading(true);
    updateDoc(updateData, {
      title: head,
      img: image,
      desc: paragraph,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoading(false);
        notify("Data Updated successfuly", "success");
        setHead("");
        setImage("");
        setparagraph("");
        setId("");
        setIsedit(false);
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setHead("");
        setImage("");
        setId("");
        setIsedit(false);
        setparagraph("");
      });
  };
  const update = (item) => {
    setIsedit(true);
    setId(item.id);
    setparagraph(item.desc);
    setHead(item.title);
    setImage(item.img);
  };
  const handleDelete = async () => {
    setLoading(true);
    const deleteVal = doc(db, "blog", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    // getBlogs();
    setIsedit(false);
    setHead("");
    setImage("");
    setparagraph("");
    setId("");
    setLoading(false);
    setshowDelete(false);
  };


  const handleImageUploadA = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      const storageRef = ref(storage, `image/${file.name}`);
      setLoadingimgA(true);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setImageA(downloadURL);
          setLoadingimgA(false);
        });
      });
    }
  };
  const handleUploadA = () => {
    // Upload the file
    if (headA === "" || paragraphA === "" || imageA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    if (BlogsA.length >= 50) {
      notify("You cannot add more than 50 items", "warn");
      return;
    }
    setLoadingA(true);

    addDoc(collection(db, "blogA"), {
      titleA: headA,
      imgA: imageA,
      descA: paragraphA,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        notify("Data Added successfuly", "success");
        setLoadingA(false);
        setHeadA("");
        setImageA("");
        setparagraphA("");
      })
      .catch((error) => {
        notify("Please Try Again", "error");
        setLoadingA(false);
        setHeadA("");
        setImageA("");
        setparagraphA("");
      });
  };

  const handleUpdateA = () => {
    // Upload the file
    if (headA === "" || paragraphA === "" || imageA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "blogA", IdA);
    setLoadingA(true);
    updateDoc(updateData, {
      titleA: headA,
      imgA: imageA,
      descA: paragraphA,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Updated successfuly", "success");
        setHeadA("");
        setImageA("");
        setparagraphA("");
        setIdA("");
        setIseditA(false);
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");
        setHeadA("");
        setImageA("");
        setIdA("");
        setIseditA(false);
        setparagraphA("");
      });
  };
  const updateA = (item) => {
    setIseditA(true);
    setIdA(item.id);
    setparagraphA(item.descA);
    setHeadA(item.titleA);
    setImageA(item.imgA);
  };
  const handleDeleteA = async () => {
    setLoadingA(true);
    const deleteVal = doc(db, "blogA", ItemidA);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    // getBlogs();
    setIseditA(false);
    setHeadA("");
    setImageA("");
    setparagraphA("");
    setIdA("");
    setLoadingA(false);
    setshowDeleteA(false);
  };
  return (
    <>
      <div className="achivement">
      <h1>Gallery</h1>
      <div className="cch">
          <div className="cchs">
            اللغة العربية{" "}
            <input
              type="checkbox"
              checked={Arabic}
              onChange={(e) => setArabic(e.target.checked)}
            />
          </div>
          <div className="cchs">
            اللغة الإنجليزية{" "}
            <input
              type="checkbox"
              checked={English}
              onChange={(e) => setEnglish(e.target.checked)}
            />
          </div>
        </div>
        {Arabic?(<>
          <Container>
          <div className="inputs-container">
            <div className="inp-container">
              <TextField
                type="file"
                inputProps={{
                  accept: "image/*",
                }}
                focused
                label="Image"
                onChange={handleImageUploadA}
              />
              {LoadingimgA ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
            </div>
            <div className="inp-container">
              <TextField
                label="العنوان"
                type="text"
                variant="outlined"
                value={headA}
                onChange={(e) => setHeadA(e.target.value)}
              />
            </div>
            <div className="inp-container">
              <TextField
                label="الوصف"
                variant="outlined"
                value={paragraphA}
                onChange={(e) => setparagraphA(e.target.value)}
              />
            </div>{" "}
            <div className="inp-container"></div>
            <div className="inp-container"></div>
          </div>
          {IseditA ? (
            LoadingA ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={handleUpdateA}>
                Edit{" "}
              </button>
            )
          ) : LoadingA ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={handleUploadA}>
              Add{" "}
            </button>
          )}
        </Container>{" "}
      <Container>
        <Table className="maint" responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                الصور <i className="fa-solid fa-user"></i>
              </th>
              <th>
                العنوان <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
                الوصف <i className="fa-solid fa-file-signature"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {BlogsA && BlogsA.length >= 0
              ? BlogsA.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <a href={item.imgA}>
                        {" "}
                        <i className="fa-solid fa-image"></i>
                      </a>
                    </td>
                    <td>{item.titleA}</td>
                    <td>{item.descA}</td>
                    <td className="icon-w">
                      <i
                        onClick={() => updateA(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    <td className="icon-w">
                      {" "}
                      <i
                        onClick={() => handleShoweDeleteA(item.id)}
                        className="fs fa-solid fa-trash-can"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
        </Container></>):(<>
          <Container>
          
          <div className="inputs-container">
            <div className="inp-container">
              <TextField
                type="file"
                inputProps={{
                  accept: "image/*",
                }}
                focused
                label="Image"
                onChange={handleImageUpload}
              />
              {Loadingimg ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
            </div>
            <div className="inp-container">
              <TextField
                label="Title"
                type="text"
                variant="outlined"
                value={head}
                onChange={(e) => setHead(e.target.value)}
              />
            </div>
            <div className="inp-container">
              <TextField
                label="paragraph"
                variant="outlined"
                value={paragraph}
                onChange={(e) => setparagraph(e.target.value)}
              />
            </div>{" "}
            <div className="inp-container"></div>
            <div className="inp-container"></div>
          </div>
          {Isedit ? (
            Loading ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={handleUpdate}>
                Edit{" "}
              </button>
            )
          ) : Loading ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={handleUpload}>
              Add{" "}
            </button>
          )}
        </Container>{" "}
      <Container>
        <Table className="maint" responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                Image <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Title <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
                Description <i className="fa-solid fa-file-signature"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Blogs && Blogs.length >= 0
              ? Blogs.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <a href={item.img}>
                        {" "}
                        <i className="fa-solid fa-image"></i>
                      </a>
                    </td>
                    <td>{item.title}</td>
                    <td>{item.desc}</td>
                    <td className="icon-w">
                      <i
                        onClick={() => update(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    <td className="icon-w">
                      {" "}
                      <i
                        onClick={() => handleShoweDelete(item.id)}
                        className="fs fa-solid fa-trash-can"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
        </Container></>)}
      
        <ToastContainer position="top-center" />
        <Modal
          show={showDelete}
          onHide={handleCloseDelete}
          backdrop="static"
          centered
          contentClassName="cont"
          keyboard={false}
          style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
        >
          {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
          <Modal.Body>Do you want to delete this Item ?</Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              className="close"
              onClick={handleCloseDelete}
            >
              Close
            </Button>
            <Button variant="primary" className="edit" onClick={handleDelete}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal
          show={showDeleteA}
          onHide={handleCloseDeleteA}
          backdrop="static"
          centered
          contentClassName="cont"
          keyboard={false}
          style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
        >
          {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
          <Modal.Body>Do you want to delete this Item ?</Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              className="close"
              onClick={handleCloseDeleteA}
            >
              Close
            </Button>
            <Button variant="primary" className="edit" onClick={handleDeleteA}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
 </div>
    </>
  );
};

export default Blog;
