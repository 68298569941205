import React, { useEffect, useState } from "react";
import "./about.css";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { Container, Spinner, Table } from "react-bootstrap";
import { TextField } from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ToastContainer } from "react-toastify";
import notify from "../../Notifcation";
const About = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);

  const [headA, setHeadA] = useState("");
  const [IseditA, setIseditA] = useState(false);
  const [imageA, setImageA] = useState("");
  const [LoadingA, setLoadingA] = useState(null);
  const [LoadingimgA, setLoadingimgA] = useState(null);
  const [IdA, setIdA] = useState(null);
  const [paragraphA, setparagraphA] = useState("");
  const [AboutsA, setAboutsA] = useState([]);

  const [head, setHead] = useState("");
  const [Isedit, setIsedit] = useState(false);
  const [image, setImage] = useState("");
  const [Loading, setLoading] = useState(null);
  const [Loadingimg, setLoadingimg] = useState(null);
  const [Id, setId] = useState(null);
  const [paragraph, setparagraph] = useState("");
  const [Abouts, setAbouts] = useState([]);
  // var fileSize = image !== null ? image.size : null; // 5 MB
  // var fileSizeInMB = convertFileSizeToMB(fileSize);
  // function convertFileSizeToMB(fileSizeInBytes) {
  //   var fileSizeInMB = parseFloat((fileSizeInBytes / (1024 * 1024)).toFixed(2));
  //   return fileSizeInMB;
  // }
  useEffect(
    () =>
      onSnapshot(collection(db, "about"), (snapshot) =>
        setAbouts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(collection(db, "aboutA"), (snapshot) =>
        setAboutsA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      setLoadingimg(true);
      const storageRef = ref(storage, `image/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
          setLoadingimg(false);
        });
      });
    }
  };
  const handleUpload = () => {
    // Upload the file
    if (head === "" || paragraph === "" || image === "") {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "about", Id);
    setLoading(true);

    updateDoc(updateData, {
      head: head,
      icon: image,
      desc: paragraph,
    })
      .then(() => {
        setLoading(false);
        notify("Data Updated successfuly", "success");
        setHead("");
        setImage("");
        setIsedit(false);
        setId("")
        setparagraph("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setHead("");
        setIsedit(false);
        setImage("");
        setparagraph("");
        setId("")
      });
  };
  const update = (item) => {
    setIsedit(true);
    setId(item.id);
    setparagraph(item.desc);
    setHead(item.head);
    setImage(item.icon);
  };

  const handleImageUploadA = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      setLoadingimgA(true);
      const storageRef = ref(storage, `image/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setImageA(downloadURL);
          setLoadingimgA(false);
        });
      });
    }
  };
  const handleUploadA = () => {
    // Upload the file
    if (headA === "" || paragraphA === "" || imageA === "") {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "aboutA", IdA);
    setLoadingA(true);

    updateDoc(updateData, {
      headA: headA,
      iconA: imageA,
      descA: paragraphA,
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Updated successfuly", "success");
        setHeadA("");
        setImageA("");
        setIseditA(false);
        setIdA("")
        setparagraphA("");
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");
        setHeadA("");
        setIseditA(false);
        setImageA("");
        setparagraphA("");
        setIdA("")
      });
  };
  const updateA = (item) => {
    setIseditA(true);
    setIdA(item.id);
    setparagraphA(item.descA);
    setHeadA(item.headA);
    setImageA(item.iconA);
  };
  return (
    <div className="achivement">

        <h1>About</h1>
        <div className="cch">
          <div className="cchs">
            اللغة العربية{" "}
            <input
              type="checkbox"
              checked={Arabic}
              onChange={(e) => setArabic(e.target.checked)}
            />
          </div>
          <div className="cchs">
            اللغة الإنجليزية{" "}
            <input
              type="checkbox"
              checked={English}
              onChange={(e) => setEnglish(e.target.checked)}
            />
          </div>
        </div>
        {Arabic?(<>
          <Container>
        <div className="inputs-container">
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              focused
              inputProps={{
                accept: "image/*",
              }}
              label="الصورة"
              type="file"
              onChange={handleImageUploadA}
            />
            {LoadingimgA ? (
              <div>
                <Spinner />
              </div>
            ) : null}
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="العنوان"
              type="text"
              variant="outlined"
              value={headA}
              onChange={(e) => setHeadA(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="الوصف"
              variant="outlined"
              value={paragraphA}
              onChange={(e) => setparagraphA(e.target.value)}
            />
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
        {IseditA ? (
          LoadingA ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={handleUploadA}>
              Edit
            </button>
          )
        ) : null}
      </Container>{" "}
      <Container>
        <Table className="maint" responsive striped bordered hover>
          <thead>
            <tr>
              <th>
              الصورة <i className="fa-solid fa-user"></i>
              </th>
              <th>
                العنوان <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
                الوصف <i className="fa-solid fa-file-signature"></i>
              </th>
              {/*            
            <th></th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {AboutsA && AboutsA.length >= 0
              ? AboutsA.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <a href={item.iconA}>
                        {" "}
                        <i className="fa-solid fa-image"></i>
                      </a>
                    </td>
                    <td>{item.headA}</td>
                    <td>{item.descA}</td>
                    <td className="icon-w">
                      <i
                        onClick={() => updateA(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    {/* <td className="icon-w">
                              {" "}
                              <i
                                     className="fs fa-solid fa-trash-can"
                                style={{ color: "red" }}
                              ></i>
                            </td> */}
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </Container></>):(<>
          <Container>
        <div className="inputs-container">
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              focused
              inputProps={{
                accept: "image/*",
              }}
              label="Image"
              type="file"
              onChange={handleImageUpload}
            />
            {Loadingimg ? (
              <div>
                <Spinner />
              </div>
            ) : null}
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="Title"
              type="text"
              variant="outlined"
              value={head}
              onChange={(e) => setHead(e.target.value)}
            />
          </div>
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="paragraph"
              variant="outlined"
              value={paragraph}
              onChange={(e) => setparagraph(e.target.value)}
            />
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
        {Isedit ? (
          Loading ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={handleUpload}>
              Edit
            </button>
          )
        ) : null}
      </Container>{" "}
      <Container>
        <Table className="maint" responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                Image <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Title <i className="fa-solid fa-layer-group"></i>
              </th>
              <th>
                Description <i className="fa-solid fa-file-signature"></i>
              </th>
              {/*            
            <th></th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Abouts && Abouts.length >= 0
              ? Abouts.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <a href={item.icon}>
                        {" "}
                        <i className="fa-solid fa-image"></i>
                      </a>
                    </td>
                    <td>{item.head}</td>
                    <td>{item.desc}</td>
                    <td className="icon-w">
                      <i
                        onClick={() => update(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    {/* <td className="icon-w">
                              {" "}
                              <i
                                     className="fs fa-solid fa-trash-can"
                                style={{ color: "red" }}
                              ></i>
                            </td> */}
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </Container>
        </>)}
    
      <ToastContainer position="top-center" />
    </div>
  );
};

export default About;
