import React, { useEffect, useState } from "react";
import "./calender.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { ToastContainer } from "react-toastify";
import notify from "../../Notifcation";
import { Button, Modal } from "react-bootstrap";
const Calender = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventName, setEventName] = useState("");
  const [Id, setId] = useState(null);
  const [Edit, setEdit] = useState(false);
  const [events, setEvents] = useState([]);
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  const Date_Click_Fun = (date) => {
    setSelectedDate(date);
  };

  const Event_Data_Update = (event) => {
    setEventName(event.target.value);
  };
  useEffect(
    () =>
      onSnapshot(collection(db, "calender"), (snapshot) =>
        setEvents(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const handleDelete = async () => {
    const deleteVal = doc(db, "calender", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setshowDelete(false);
    setId("")
    
  };

  const handleEdit = (id, name, date) => {
    const dates = new Date(date);
    setId(id);
    setEdit(true);
    setSelectedDate(dates);
    setEventName(name);
  };

  const handleUpdate = async () => {
    const updateData = doc(db, "calender", Id);
    await updateDoc(updateData, {
      eventname: eventName,
      date: selectedDate.toDateString(),
    });
    notify("Event Edited successfuly", "success");
    setEventName("")
    setSelectedDate("")
    setId("");
    setEdit(false);
  };
  const submitEvent = async (e) => {
    e.preventDefault();
    if (selectedDate && eventName) {
      try {
        await addDoc(collection(db, "calender"), {
          date: selectedDate.toDateString(),
          eventname: eventName,
        });
        setEventName("");
        setId("");
        notify("Event Added successfuly", "success");
      } catch (error) {
        notify("Failed to add event", "error");
        setEventName("");
        setId("");
      }
    }
  };
  return (
    <div className="calenderr">
      <h2 className="text-center"style={{color:"#b59e6c"}}>
      Welcome To National School Calender
      </h2>
      <div className="container">
        <div className="calendar-container">
          <Calendar
            value={selectedDate}
            onClickDay={Date_Click_Fun}
            tileClassName={({ date }) =>
              selectedDate &&
              date.toDateString() === selectedDate.toDateString()
                ? "selected"
                : events.some((event) => event.date === date.toDateString())
                ? "event-marked"
                : ""
            }
          />{" "}
        </div>
        <div className="event-container">
          {" "}
          {selectedDate && (
            <div className="event-form">
              <h2> Create Event </h2>{" "}
              <p> Selected Date: {selectedDate.toDateString()} </p>{" "}
              <input
                type="text"
                placeholder="Event Name"
                value={eventName}
                onChange={Event_Data_Update}
              />{" "}
              {Edit ? (
                <button className="create-btn" onClick={handleUpdate}>
                  Edit Event{" "}
                </button>
              ) : (
                <button className="create-btn" onClick={submitEvent}>
                  Add Event{" "}
                </button>
              )}
            </div>
          )}
          {events.length > 0 && selectedDate && (
            <div className="event-list">
              <h2> My Created Event List </h2>{" "}
              <div className="event-cards">
                {" "}
                {events.map((event,idx) =>
                  event.date === selectedDate.toDateString() ? (
                    <div key={idx} className="event-card">
                      <div className="event-card-header">
                        <span className="event-date"> {event.date} </span>{" "}
                        <div className="event-actions">
                          <button
                            className="update-btn"
                            onClick={() =>
                              handleEdit(event.id, event.eventname, event.date)
                            }
                          >
                            Update Event{" "}
                          </button>{" "}
                          <button
                            className="delete-btn"
                            onClick={() => handleShoweDelete(event.id)}
                          >
                            Delete Event{" "}
                          </button>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="event-card-body">
                        <p className="event-title"> {event.eventname} </p>{" "}
                      </div>{" "}
                    </div>
                  ) : null
                )}{" "}
              </div>{" "}
            </div>
          )}{" "}
        </div>{" "}
      </div>{" "}
      <ToastContainer position="top-center" />
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDelete}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calender;
