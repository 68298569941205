import React, { useEffect } from "react";
import "./navbar.css";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo1 from "../../img/logo.png";
import logo2 from "../../img/11-removebg-preview.png";
import logo3 from "../../img/22-removebg-preview.png";

const Navbarr = ({ setArabic, Arabic }) => {
  const item = localStorage.getItem("Arabic");
  const chandge = (e) => {
    localStorage.setItem("Arabic", e.target.checked);
    setArabic(e.target.checked);
  };
  useEffect(() => {
    setArabic(item);
  }, [item]);

  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <div className="log">
              <img className="on"  src={logo1} alt="" />
              <h6 style={{ fontFamily: "Playfair Display, serif" }}>
                NATIONAL
              </h6>
            </div>
            <div className="log">
              <img className="sn"  src={logo2} alt="" />
              <h6 style={{ fontFamily: "Playfair Display, serif" }}>
                AMERICAN
              </h6>
            </div>
            <div className="log">
              <img className="tn"  src={logo3} alt="" />
              <h6 style={{ fontFamily: "Playfair Display, serif" }}>ARABIC</h6>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto nn">
              <Nav.Link as={NavLink} to="/">
                {Arabic === "true" ? "الرئيسية" : "Home"}
              </Nav.Link>
              <Nav.Link as={NavLink} to="/galary">
                {Arabic === "true" ? "ألبوم الصور" : "Gallery"}
              </Nav.Link>
              <Nav.Link as={NavLink} to="/activites">
                {Arabic === "true" ? "الأنشطة" : "Activities"}
              </Nav.Link>
              <Nav.Link as={NavLink} to="/facilities">
                {Arabic === "true" ? "مرافق المدرسة" : "Facilities"}
              </Nav.Link>
              {/* <Nav.Link as={Link}  to="/home#AboutUS">About US</Nav.Link> */}
              <Nav.Link as={NavLink} to="/admission">
                {Arabic === "true" ? "تسجيل الطلاب" : "Admission"}
              </Nav.Link>

              <Nav.Link as={NavLink} to="/workwithuss">
                {Arabic === "true" ? "وظائف" : "Work with Us"}
              </Nav.Link>
              <Nav.Link as={NavLink} to="/aboutuspage">
                {Arabic === "true" ? "من نحن" : "About Us"}
              </Nav.Link>
              <NavDropdown
                title={Arabic === "true" ? "الأجنده" : "Calendar"}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={NavLink} to="/calenderclient">
                  National School
                </NavDropdown.Item>
                {/* <NavDropdown.Divider /> */}

                <NavDropdown.Item as={NavLink} to="/calenderclientamerican">
                  American School{" "}
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/calenderclientarabic">
                  Arabic School
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Navbar.Brand>
          <label className="switch">
            <input
              type="checkbox"
              checked={Arabic === "true" ? true : false}
              // value={Arabic}
              onChange={chandge}
            />
            <span className="slider round"></span>
          </label>
        </Navbar.Brand>
          </Navbar.Collapse>
        </Container>
       
      </Navbar>
    </>
  );
};

export default Navbarr;
