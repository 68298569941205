import React, { useEffect, useState } from "react";
import "./optionadmission.css";
import { db } from "../../../firebase";
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import { TextField } from "@mui/material";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import notify from "../../Notifcation";
import { ToastContainer } from "react-toastify";
const Optionadmission = () => {
  const [nationality, setnNtionality] = useState("");
  const [Idnationality, setIdnationality] = useState(null);

  const [divisionname, setdivisionname] = useState("");
  const [IDDivisionname, setIDDivisionname] = useState(null);

  const [Acadimicyear, setAcadimicyear] = useState(null);
  const [IdAcadimicyear, setIdAcadimicyear] = useState(null);

  const [Level, setLevel] = useState(null);
  const [IdLevel, setIdLevel] = useState(null);

  const [Loadingnam, setLoadingnat] = useState(null);
  const [Loadingacad, setLoadingacad] = useState(null);
  const [Loadingdivis, setLoadingdivis] = useState(null);
  const [Loadinglevel, setLoadinglevel] = useState(null);

  const [Iseditnat, setIseditnat] = useState(false);
  const [Iseditacad, setIseditacad] = useState(false);
  const [Iseditdivis, setIseditdivis] = useState(false);
  const [Iseditlevel, setIseditlevel] = useState(false);

  const [Acadimicyears, setAcadimicyears] = useState("");
  const [divisionnames, setdivisionnames] = useState("");
  const [Levels, setLevels] = useState("");
  const [nationalitys, setnationalitys] = useState("");
  const [showDeletenat, setshowDeletenat] = useState(false);
  const [showDeleteacad, setshowDeleteacad] = useState(false);
  const [showDeletedivis, setshowDeletedivis] = useState(false);
  const [showDeletelevel, setshowDeletelevel] = useState(false);

  const handleCloseDeletenat = () => setshowDeletenat(false);
  const handleShoweDeletenat = (itemid) => {
    setshowDeletenat(true);
    setIdnationality(itemid);
  };
  const handleCloseDeleteacad = () => setshowDeleteacad(false);
  const handleShoweDeleteacad = (itemid) => {
    setshowDeleteacad(true);
    setIdAcadimicyear(itemid);
  };
  const handleCloseDeletelevel = () => setshowDeletelevel(false);
  const handleShoweDeletelevel = (itemid) => {
    setshowDeletelevel(true);
    setIdLevel(itemid);
  };
  const handleCloseDeletedivis = () => setshowDeletedivis(false);
  const handleShoweDeletedivis = (itemid) => {
    setshowDeletedivis(true);
    setIDDivisionname(itemid);
  };
  useEffect(
    () =>
      onSnapshot(query(collection(db, "divisionname"), orderBy("createdAt", "asc")), (snapshot) =>
        setdivisionnames(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "acadimicyear"), orderBy("createdAt", "asc")), (snapshot) =>
        setAcadimicyears(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "level"), orderBy("createdAt", "asc")), (snapshot) =>
        setLevels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "nationality"), orderBy("createdAt", "asc")), (snapshot) =>
        setnationalitys(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  const submitNationality = async (e) => {
    e.preventDefault();
    if (nationality === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoadingnat(true);
      await addDoc(collection(db, "nationality"), {
        name: nationality,
        createdAt: Timestamp.now(),

      });
      setLoadingnat(false);
      setnNtionality("");
      notify("Data Added successfuly", "success");
    } catch (error) {
      setnNtionality("");
      notify("Please Try Again", "error");
    }
  };
  const submitdivision = async (e) => {
    e.preventDefault();
    if (divisionname === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoadingdivis(true);

      await addDoc(collection(db, "divisionname"), {
        name: divisionname,
        createdAt: Timestamp.now(),
      });
      setdivisionname("");
      setLoadingdivis(false);
      notify("Data Added successfuly", "success");
    } catch (error) {
      setdivisionname("");
      notify("Please Try Again", "error");
    }
  };
  const submitLevel = async (e) => {
    e.preventDefault();
    if (Level === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoadinglevel(true);
      await addDoc(collection(db, "level"), {
        name: Level,
        createdAt: Timestamp.now(),
      });
      setLoadinglevel(false);
      setLevel("");
      notify("Data Added successfuly", "success");
    } catch (error) {
      notify("Please Try Again", "error");
      setLevel("");
    }
  };
  const submitLacadimicyear = async (e) => {
    e.preventDefault();
    if (Acadimicyear === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    try {
      setLoadingacad(true);
      await addDoc(collection(db, "acadimicyear"), {
        name: Acadimicyear,
        createdAt: Timestamp.now(),
      });
      setLoadingacad(false);
      setAcadimicyear("");
      notify("Data Added successfuly", "success");
    } catch (error) {
      notify("Please Try Again", "error");
      setAcadimicyear("");
    }
  };
  const updateacadimicyear = (item) => {
    setIseditacad(true);
    setAcadimicyear(item.name);
    setIdAcadimicyear(item.id);
  };
  const updatedivision = (item) => {
    setIseditdivis(true);
    setdivisionname(item.name);
    setIDDivisionname(item.id);
  };
  const updateLevel = (item) => {
    setIseditlevel(true);
    setLevel(item.name);
    setIdLevel(item.id);
  };
  const updatenationality = (item) => {
    setIseditnat(true);
    setnNtionality(item.name);
    setIdnationality(item.id);
  };
  const handleDeletenationality = async () => {
    const deleteVal = doc(db, "nationality", Idnationality);
    await deleteDoc(deleteVal);
    notify("data Deleted successfuly", "success");
    setnNtionality("");
    setIseditnat(false);
    setshowDeletenat(false);
  };
  const handleDeletacadimicyear = async () => {
    const deleteVal = doc(db, "acadimicyear", IdAcadimicyear);
    await deleteDoc(deleteVal);
    notify("data Deleted successfuly", "success");
    setAcadimicyear("");
    setIseditacad(false);
    setshowDeleteacad(false);
  };
  const handleDeletLevel = async () => {
    const deleteVal = doc(db, "level", IdLevel);
    await deleteDoc(deleteVal);
    notify("data Deleted successfuly", "success");
    setLevel("");
    setIseditlevel(false);
    setshowDeletelevel(false);
  };
  const handleDeletedivisionname = async () => {
    const deleteVal = doc(db, "divisionname", IDDivisionname);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setshowDeletedivis(false);
    setdivisionname("");
    setIseditdivis(false);
  };
  const handleUpdateacadimicyear = async () => {
    setLoadingacad(true);
    if (Acadimicyear === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "acadimicyear", IdAcadimicyear);
    await updateDoc(updateData, {
      name: Acadimicyear,
      createdAt: Timestamp.now(),
    });
    setAcadimicyear("");
    notify("data Edited successfuly", "success");
    setIseditacad(false);
    setLoadingacad(false);
  };
  const handleUpdatedivisioname = async () => {
    setLoadingdivis(true);
    if (Acadimicyear === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "divisionname", IDDivisionname);
    await updateDoc(updateData, {
      name: divisionname,
      createdAt: Timestamp.now(),
    });
    setdivisionname("");
    notify("data Edited successfuly", "success");
    setIseditdivis(false);
    setLoadingdivis(false);
  };
  const handleUpdateLevel = async () => {
    setLoadinglevel(true);
    if (Level === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "level", IdLevel);
    await updateDoc(updateData, {
      name: Level,
      createdAt: Timestamp.now(),
    });
    setLevel("");
    notify("data Edited successfuly", "success");
    setIseditlevel(false);
    setLoadinglevel(false);
  };
  const handleUpdatenationality = async () => {
    setLoadingnat(true);
    if (nationality === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    const updateData = doc(db, "nationality", Idnationality);
    await updateDoc(updateData, {
      name: nationality,
      createdAt: Timestamp.now(),
    });
    setnNtionality("");
    notify("data Edited successfuly", "success");
    setIseditnat(false);
    setLoadingnat(false);
  };

  return (
    <div className="achivement">
      <Container>
        <h1>Admission Options</h1>
        <div className="inputs-container">
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="Division Name"
              type="text"
              variant="outlined"
              value={divisionname}
              onChange={(e) => setdivisionname(e.target.value)}
            />
            {Iseditdivis ? (
              Loadingdivis ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdatedivisioname}>
                  Edit
                </button>
              )
            ) : Loadingdivis ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitdivision}>
                Add
              </button>
            )}
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Division Name <i className="fa-solid fa-user"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {divisionnames && divisionnames.length >= 0
                  ? divisionnames.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.name}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => updatedivision(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDeletedivis(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>{" "}
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="Academic Year"
              type="text"
              variant="outlined"
              value={Acadimicyear}
              onChange={(e) => setAcadimicyear(e.target.value)}

              //   className="ww"
            />
            {Iseditacad ? (
              Loadingacad ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button
                  className="buttonadd"
                  onClick={handleUpdateacadimicyear}
                >
                  Edit
                </button>
              )
            ) : Loadingacad ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitLacadimicyear}>
                Add
              </button>
            )}
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Academic Year <i className="fa-solid fa-user"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Acadimicyears && Acadimicyears.length >= 0
                  ? Acadimicyears.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.name}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => updateacadimicyear(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDeleteacad(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
          {/* <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="Level"
              type="text"
              variant="outlined"
              value={Level}
              onChange={(e) => setLevel(e.target.value)}

              //   className="ww"
            />
            {Iseditlevel ? (
              Loadinglevel ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdateLevel}>
                  Edit
                </button>
              )
            ) : Loadinglevel ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitLevel}>
                Add
              </button>
            )}
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Level <i className="fa-solid fa-user"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Levels && Levels.length >= 0
                  ? Levels.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.name}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => updateLevel(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDeletelevel(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div> */}
          <div className="inp-container">
            <TextField
              id="outlined-basic"
              label="Nationality"
              variant="outlined"
              value={nationality}
              onChange={(e) => setnNtionality(e.target.value)}
            />
            {Iseditnat ? (
              Loadingnam ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpdatenationality}>
                  Edit
                </button>
              )
            ) : Loadingnam ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={submitNationality}>
                Add
              </button>
            )}
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Nationality <i className="fa-solid fa-user"></i>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {nationalitys && nationalitys.length >= 0
                  ? nationalitys.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.name}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => updatenationality(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        <td className="icon-w">
                          {" "}
                          <i
                            onClick={() => handleShoweDeletenat(item.id)}
                            className="fs fa-solid fa-trash-can"
                            style={{ color: "red" }}
                          ></i>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
      </Container>{" "}
      <ToastContainer position="top-center" />
      <Modal
        show={showDeleteacad}
        onHide={handleCloseDeleteacad}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDeleteacad}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="edit"
            onClick={handleDeletacadimicyear}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeletedivis}
        onHide={handleCloseDeletedivis}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDeletedivis}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="edit"
            onClick={handleDeletedivisionname}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeletelevel}
        onHide={handleCloseDeletelevel}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDeletelevel}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDeletLevel}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeletenat}
        onHide={handleCloseDeletenat}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDeletenat}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="edit"
            onClick={handleDeletenationality}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Optionadmission;
