import React, { useState } from "react";
import "./login.css";
import { Container, Spinner } from "react-bootstrap";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import notify from "../Notifcation";
import { ToastContainer } from "react-toastify";
// import { Link } from "react-router-dom";
const Login = () => {
  const [Email, setEmail] = useState("");

  const [Password, setPassword] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onPasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };
  const toggle = () => {
    if (Password === "password") {
      setPassword("text");
      return;
    }
    setPassword("password");
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (Email === "") {
      notify("Email is required", "error");
      return;
    }
    if (passwordInput === "") {
      notify("Password is required", "error");
      return;
    }
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, Email, passwordInput);
      setLoading(false);
      setEmail("");
      setPassword("");
      navigate("/admin");
    } catch (e) {
      setLoading(false);
      notify("incorrect email or password", "error");
    }
  };
  return (
    <div className="login">
      <Container>
        <div className="main-log">
          <div className="login-container">
            <h2>Login Admin</h2>
            <div className="login">
              <input
                autoComplete="off"
                type="email"
                className="form-control"
                placeholder="Email Address"
                value={Email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                variant="outlined"
                type={Password}
                autoComplete="off"
                onChange={onPasswordChange}
                value={passwordInput}
                placeholder="Password"
                className="form-control"
              />
              <button className="btn btn-primary e" onClick={toggle}>
                {Password === "password" ? (
                  <svg
                    width="20"
                    height="17"
                    fill="currentColor"
                    className="bi bi-eye-slash-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="17"
                    fill="currentColor"
                    className="bi bi-eye-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                  </svg>
                )}
              </button>
              {Loading ? (
                <button style={{ height: "52px" }}>
                  <Spinner />
                </button>
              ) : (
                <button style={{ height: "52px" }} onClick={handlesubmit}>
                  Login
                </button>
              )}

              <Link to="/forgetpassword">Forget your password..?</Link>

              {/* <Link to="/register">Creat Admin</Link> */}
              {/* <div className="social">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-google"></i>
              </div> */}
            </div>
          </div>
        </div>
        <ToastContainer position="top-center" />
      </Container>
    </div>
  );
};
export default Login;
