import React, { useState } from "react";
import "./conatct.css";
import { Fade } from "react-awesome-reveal";
import { TextField } from "@mui/material";
import notify from "../Notifcation";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
const Conatct = ({Arabic}) => {
  const [name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Loading, setLoading] = useState("");
  const submitcontact = async (e) => {
    e.preventDefault();
    if (name === "" || Email === "" || Message === "") {
      notify("Please Enter the require data", "warn");
      return;
    }
    
    try {
      setLoading(true);
      await addDoc(collection(db, "contact"), {
        name,
        email: Email,
        message: Message,
      });
      
      setLoading(false);
      notify("Data sent successfuly", "success");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      notify("Please Try Again", "error");
      setName("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <div id="contsctus">
      {" "}
      <h3 className="text-center tit">{Arabic === "true" ? "تواصل معنا" : "Contact Us"}</h3>
      <section id="contact">
        <div className="contact-box">
          <div className="contact-links">
            <Fade direction="left" triggerOnce="false">
              <h2>FUTURE LEADERS SCHOOL</h2>
              <div className="links">
                <div className="link">
                  <a href={Arabic === "true" ?'https://www.facebook.com/people/Future-Leaders-Arabic-School/61554522740825/':'https://www.facebook.com/people/Future-Leaders/61554404545043/'}>
                    {" "}
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </div>
                <div className="link">
                  <a href="#d">
                    {" "}
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </div>
                <div className="link">
                  <a href="#d">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </div>
                <div className="link">
                  <a href="#d">
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </div>
              </div>
            </Fade>
          </div>

          <div className="contact-form-wrapper">
            <Fade direction="right" triggerOnce="false">
              <form>
                <div className="form-item">
                  <TextField
                    label={`${Arabic==='true'?'الأسم':"Name"}`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div className="form-item">
                  <TextField
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    label={`${Arabic==='true'?'البريد الألكتروني':"Email"}`}
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div className="form-item">
                  <TextField
                    value={Message}
                    onChange={(e) => setMessage(e.target.value)}
                    label={`${Arabic==='true'?'الرسالة':"Message"}`}
                    type="text"
                    multiline
                    variant="outlined"
                  />
                </div>
                {Loading ? (
                  <button className="buttonadd">
                    <Spinner />
                  </button>
                ) : (
                  <button className="buttonadd" onClick={submitcontact}>
                    Send
                  </button>
                )}
              </form>
            </Fade>
          </div>
        </div>
      </section>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Conatct;
