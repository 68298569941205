import React, { useEffect, useState } from "react";
import "./socialmedia.css";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Container, Spinner, Table } from "react-bootstrap";
import { TextField } from "@mui/material";
import { ToastContainer } from "react-toastify";
import notify from "../../Notifcation";
const Socialmediaa = () => {
  const [Arabic, setArabic] = useState(false);
  const [English, setEnglish] = useState(true);
  useEffect(() => {
    if (Arabic === true) {
      setEnglish(false);
    }
  }, [Arabic]);
  useEffect(() => {
    if (English === true) {
      setArabic(false);
    }
  }, [English]);

  const [FacebookA, setFacebookA] = useState("");
  const [IseditA, setIseditA] = useState(false);
  const [TwitterA, setTwitterA] = useState("");
  const [LoadingA, setLoadingA] = useState(null);
  const [IdA, setIdA] = useState(null);
  const [YoutubeA, setYoutubeA] = useState("");
  const [LinkedinA, setLinkedinA] = useState("");
  const [SocialMediaA, setSocialMediaA] = useState([]);

  const [Facebook, setFacebook] = useState("");
  const [Isedit, setIsedit] = useState(false);
  const [Twitter, setTwitter] = useState("");
  const [Loading, setLoading] = useState(null);
  const [Id, setId] = useState(null);
  const [Youtube, setYoutube] = useState("");
  const [Linkedin, setLinkedin] = useState("");
  const [SocialMedia, setSocialMedia] = useState([]);

  // var fileSize = image !== null ? image.size : null; // 5 MB
  // var fileSizeInMB = convertFileSizeToMB(fileSize);
  // function convertFileSizeToMB(fileSizeInBytes) {
  //   var fileSizeInMB = parseFloat((fileSizeInBytes / (1024 * 1024)).toFixed(2));
  //   return fileSizeInMB;
  // }
  useEffect(
    () =>
      onSnapshot(collection(db, "socialmedia"), (snapshot) =>
        setSocialMedia(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(collection(db, "socialmediaA"), (snapshot) =>
        setSocialMediaA(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  const handleUpload = () => {
    // Upload the file
    if (
      Facebook === "" ||
      Twitter === "" ||
      Youtube === "" ||
      Linkedin === ""
    ) {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "socialmedia", Id);
    setLoading(true);

    updateDoc(updateData, {
      facebook: Facebook,
      twitter: Twitter,
      youtube: Youtube,
      linkedin: Linkedin,
    })
      .then(() => {
        setLoading(false);
        notify("Data Updated successfuly", "success");
        setFacebook("");
        setTwitter("");
        setIsedit(false);
        setId("");
        setYoutube("");
        setLinkedin("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setFacebook("");
        setTwitter("");
        setIsedit(false);
        setId("");
        setYoutube("");
        setLinkedin("");
      });
  };
  const update = (item) => {
    setIsedit(true);
    setId(item.id);
    setFacebook(item.facebook);
    setTwitter(item.twitter);
    setYoutube(item.youtube);
    setLinkedin(item.linkedin);
  };
  const handleUploadA = () => {
    // Upload the file
    if (
      FacebookA === "" ||
      TwitterA === "" ||
      YoutubeA === "" ||
      LinkedinA === ""
    ) {
      notify("Please Enter the require data", "warn");
      return;
    }

    const updateData = doc(db, "socialmediaA", IdA);
    setLoadingA(true);

    updateDoc(updateData, {
      facebookA: FacebookA,
      twitterA: TwitterA,
      YoutubeA: YoutubeA,
      linkedinA: LinkedinA,
    })
      .then(() => {
        setLoadingA(false);
        notify("Data Updated successfuly", "success");
        setFacebookA("");
        setTwitterA("");
        setIseditA(false);
        setIdA("");
        setYoutubeA("");
        setLinkedinA("");
      })
      .catch((error) => {
        setLoadingA(false);
        notify("Please Try Again", "error");
        setFacebookA("");
        setTwitterA("");
        setIseditA(false);
        setIdA("");
        setYoutubeA("");
        setLinkedinA("");
      });
  };
  const updateA = (item) => {
    setIseditA(true);
    setIdA(item.id);
    setFacebookA(item.facebookA);
    setTwitterA(item.twitterA);
    setYoutubeA(item.youtubeA);
    setLinkedinA(item.linkedinA);
  };
  return (
    <div className="achivement">
      <h1>Social media</h1>
      <div className="cch">
        <div className="cchs">
          اللغة العربية{" "}
          <input
            type="checkbox"
            checked={Arabic}
            onChange={(e) => setArabic(e.target.checked)}
          />
        </div>
        <div className="cchs">
          اللغة الإنجليزية{" "}
          <input
            type="checkbox"
            checked={English}
            onChange={(e) => setEnglish(e.target.checked)}
          />
        </div>
      </div>
      {Arabic ? (
        <>
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="الفيسبوك"
                  type="text"
                  variant="outlined"
                  value={FacebookA}
                  onChange={(e) => setFacebookA(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="تويتر"
                  type="text"
                  variant="outlined"
                  value={TwitterA}
                  onChange={(e) => setTwitterA(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="اليوتيوب"
                  type="text"
                  variant="outlined"
                  value={YoutubeA}
                  onChange={(e) => setYoutubeA(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="لينكدان"
                  type="text"
                  variant="outlined"
                  value={LinkedinA}
                  onChange={(e) => setLinkedinA(e.target.value)}
                />
              </div>
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {IseditA ? (
              LoadingA ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUploadA}>
                  Edit
                </button>
              )
            ) : null}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    الفيسبوك <i className="fa-solid fa-user"></i>
                  </th>
                  <th>
                    تويتر <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    اليوتيوب <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th>
                    لينكدان <i className="fa-solid fa-file-signature"></i>
                  </th>
                  {/*            
            <th></th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {SocialMediaA && SocialMediaA.length >= 0
                  ? SocialMediaA.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.facebookA}</td>
                        <td>{item.twitterA}</td>
                        <td>{item.youtubeA}</td>
                        <td>{item.linkedinA}</td>

                        <td className="icon-w">
                          <i
                            onClick={() => updateA(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        {/* <td className="icon-w">
                              {" "}
                              <i
                                     className="fs fa-solid fa-trash-can"
                                style={{ color: "red" }}
                              ></i>
                            </td> */}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      ) : (
        <>
          <Container>
            <div className="inputs-container">
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="Facebook"
                  type="text"
                  variant="outlined"
                  value={Facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="Twitter"
                  type="text"
                  variant="outlined"
                  value={Twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="Youtube"
                  type="text"
                  variant="outlined"
                  value={Youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                />
              </div>
              <div className="inp-container">
                <TextField
                  id="outlined-basic"
                  label="Linkedin"
                  type="text"
                  variant="outlined"
                  value={Linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </div>
              <div className="inp-container"></div>
              <div className="inp-container"></div>
            </div>
            {Isedit ? (
              Loading ? (
                <button className="buttonadd">
                  <Spinner />
                </button>
              ) : (
                <button className="buttonadd" onClick={handleUpload}>
                  Edit
                </button>
              )
            ) : null}
          </Container>{" "}
          <Container>
            <Table className="maint" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    Facebook <i className="fa-solid fa-user"></i>
                  </th>
                  <th>
                    Twitter <i className="fa-solid fa-layer-group"></i>
                  </th>
                  <th>
                    Youtube <i className="fa-solid fa-file-signature"></i>
                  </th>
                  <th>
                    Linkedin <i className="fa-solid fa-file-signature"></i>
                  </th>
                  {/*            
            <th></th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {SocialMedia && SocialMedia.length >= 0
                  ? SocialMedia.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.facebook}</td>
                        <td>{item.twitter}</td>
                        <td>{item.youtube}</td>
                        <td>{item.linkedin}</td>
                        <td className="icon-w">
                          <i
                            onClick={() => update(item)}
                            className="fa fa-solid fa-pen-to-square"
                            style={{ color: "rgb(0 44 146)" }}
                          ></i>
                        </td>
                        {/* <td className="icon-w">
                              {" "}
                              <i
                                     className="fs fa-solid fa-trash-can"
                                style={{ color: "red" }}
                              ></i>
                            </td> */}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </Container>
        </>
      )}

      <ToastContainer position="top-center" />
    </div>
  );
};

export default Socialmediaa;
