import React, { createContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import Loader from "../components/loader/loader";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const Unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setloading(false);
    });
    return Unsubscribe;
  }, []);
  if (loading) return  <Loader />;
  return (
    <AuthContext.Provider value={{user}}>
        {children}
    </AuthContext.Provider>
  )
};
