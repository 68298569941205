import React, { useEffect, useState } from "react";
import "./card.css";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const Card = ({ Arabic }) => {
  const [Blogs, setBlogs] = useState([]);
  const [BlogsA, setBlogsA] = useState([]);
  useEffect(
    () =>
      onSnapshot(query(collection(db, "blog"), orderBy("createdAt", "asc")) ,(snapshot) =>
        setBlogs(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "blogA"), orderBy("createdAt", "asc")), (snapshot) =>
        setBlogsA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  return (
    <div className="Popupflag">
      {" "}
      <Link to={"/galary"} style={{ textDecoration: "none" }}>
        <h3 className="tit">{Arabic === "true" ? "الألبوم" : "Gallery"}</h3>
      </Link>
      <Container>
        <main id="Popupflag" className="Popup">
        {Arabic === "true" ? (<>
          {BlogsA && BlogsA.length >= 0
            ? BlogsA.slice(0, 4).map((item, idx) => (
                <div className="gala" key={idx}>
                  <div className="overlayegala"></div>
                  <img src={item.imgA} alt="Mountains" />
                  <div className="cont">
                    <h1>{item.titleA}</h1>
                    <p>{item.descA}</p>
                  </div>
                </div>
              ))
            : null}</>) : (<>
          {Blogs && Blogs.length >= 0
            ? Blogs.slice(0, 4).map((item, idx) => (
                <div className="gala" key={idx}>
                  <div className="overlayegala"></div>
                  <img src={item.img} alt="Mountains" />
                  <div className="cont">
                    <h1>{item.title}</h1>
                    <p>{item.desc}</p>
                  </div>
                </div>
              ))
            : null}</>)}
       
        </main>
      </Container>
    </div>
  );
};

export default Card;
