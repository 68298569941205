import React, { useEffect, useState } from "react";
import "./timeline.css";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { Fade } from "react-awesome-reveal";
const Timeline = ({ Arabic }) => {
  const [Achieve, setAchieve] = useState([]);
  const [AchieveA, setAchieveA] = useState([]);
  useEffect(
    () =>
      onSnapshot(query(collection(db, "Achivements"), orderBy("createdAt", "asc")), (snapshot) =>
        setAchieve(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "AchivementsA"), orderBy("createdAt", "asc")), (snapshot) =>
        setAchieveA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const [Yearhead, setYearhead] = useState([]);
  const [YearheadA, setYearheadA] = useState([]);

  useEffect(() => {
    const set = new Set();
    //eslint-disable-next-line
    Achieve.map((d) => {
      set.add(d.year);
    });
    const Cat = Array.from(set);
    setYearhead(Cat);
  }, [Achieve]);
  useEffect(() => {
    const set = new Set();
    //eslint-disable-next-line
    AchieveA.map((d) => {
      set.add(d.yearA);
    });
    const Cat = Array.from(set);
    setYearheadA(Cat);
  }, [AchieveA]);
  return (
    <div className="timeline">
      <h3 className="tit">
        {Arabic === "true" ? "إنجازاتنا" : "Our Achievements"}
      </h3>
      <div className="container">
        <div className="timeline-content">
          {Arabic === "true" ? (
            <>
              {" "}
              {YearheadA && YearheadA.length >= 0
                ? YearheadA.map((y, idx) => {
                    return (
                      <div key={idx}>
                        <div className="year">{y}</div>
                        {AchieveA && AchieveA.length >= 0
                          ? AchieveA.map((ach, idxs) =>
                              y === ach.yearA ? (
                                <>
                                  <div
                                    key={idxs}
                                    className={`${ach.directionA}`}
                                  >
                                    <div className="content">
                                      <Fade direction="up" triggerOnce="false">
                                        <h3>{ach.headA}</h3>
                                        <p>{ach.descA}</p>
                                      </Fade>
                                    </div>
                                  </div>
                                  <div className="clearfix"></div>
                                </>
                              ) : null
                            )
                          : null}
                      </div>
                    );
                  })
                : null}
            </>
          ) : (
            <>
              {Yearhead && Yearhead.length >= 0
                ? Yearhead.map((y, idx) => {
                    return (
                      <div key={idx}>
                        <div className="year">{y}</div>
                        {Achieve && Achieve.length >= 0
                          ? Achieve.map((ach, idxs) =>
                              y === ach.year ? (
                                <>
                                  <div
                                    key={idxs}
                                    className={`${ach.direction}`}
                                  >
                                    <div className="content">
                                      <Fade direction="up" triggerOnce="false">
                                        <h3>{ach.head}</h3>
                                        <p>{ach.desc}</p>
                                      </Fade>
                                    </div>
                                  </div>
                                  <div className="clearfix"></div>
                                </>
                              ) : null
                            )
                          : null}
                      </div>
                    );
                  })
                : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
