import React, { useEffect, useRef, useState } from "react";
import "./workwithus.css";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import defaultt from "../../../img/icons8-person-64 1.png";
import { db, storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import notify from "../../Notifcation";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import { useDownloadExcel } from "react-export-table-to-excel";
const Workwithus = () => {
  const [Isedit, setIsedit] = useState(false);
  const [Datee, setDate] = useState("");
  const [picture, setpicture] = useState("");
  const [Imagesrc, setImagesrc] = useState("");
  const [picturebase64, setpicturebase64] = useState("");
  const [Cv, setCv] = useState("");
  const [FullNmae, setFullNmae] = useState("");
  const [Loadingimg, setLoadingimg] = useState(null);
  const [LoadingCV, setLoadingCV] = useState(null);
  const [Nationality, setNationality] = useState("");
  const [Residentloc, setResidentloc] = useState("");
  const [Id, setId] = useState(null);
  const [Address, setAddress] = useState("");
  const [Phone, setPhone] = useState("");
  const [Educationqu, setEducationqu] = useState("");
  const [Level, setLevel] = useState("");
  const [Email, setEmail] = useState("");
  const [JobTitle, setJobTitle] = useState("");
  const [PresentOcc, setPresentOcc] = useState("");
  const [ForeignLang1, setForeignLang1] = useState("");
  const [Level1, setLevel1] = useState("");
  const [ForeignLang2, setForeignLang2] = useState("");
  const [Level2, setLevel2] = useState("");
  const [ForeignLang3, setForeignLang3] = useState("");
  const [Level3, setLevel3] = useState("");
  const [Teachingex, setTeachingex] = useState("");
  const [Courses, setCourses] = useState("");
  const [Computercours, setComputercours] = useState("");
  const [Maritalstatus, setMaritalstatus] = useState("");
  const [Numberchild, setNumberchild] = useState("");
  const [Diseases, setDiseases] = useState("");
  const [Handycapping, setHandycapping] = useState("");
  const [Loading, setLoading] = useState("");
  const [Workwithuss, setWorkwithuss] = useState([]);
  const tableRef = useRef(null);
  const [filteredList, setFilteredList] = useState(Workwithuss);
  const [showDelete, setshowDelete] = useState(false);
  const [arabic, setArabic] = useState("");
  const item = localStorage.getItem("Arabic");
  useEffect(() => {
    setArabic(item);
  }, [item]);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "work with us Report",
    sheet: "work with us Report",
  });
  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "workwithus"), orderBy("createdAt", "asc")),
        (snapshot) =>
          setWorkwithuss(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          )
      ),
    []
  );
  useEffect(() => {
    setFilteredList(Workwithuss);
  }, [Workwithuss]);
  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    var updatedList = [...Workwithuss];
    // Include all elements which includes the search query
    updatedList = updatedList.filter(
      (item) =>
        item.FullName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Nationality.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Phone.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.Jobtitle.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    // Trigger render with updated values
    setFilteredList(updatedList);
  };

  const renderPreview = () => {
    if (Imagesrc) {
      const reader = new FileReader();
      reader.readAsDataURL(Imagesrc);
      reader.onloadend = () => {
        setpicturebase64(reader.result);
      };
      return <img id="profile" src={picturebase64} alt="your profile pic" />;
    } else if (picturebase64 !== "") {
      return <img src={picturebase64} alt="your profile pic" />;
    } else {
      return <img src={defaultt} alt="default profile pic" />;
    }
  };
  const fileUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this image the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      setImagesrc(file);
      setLoadingimg(true);
      const storageRefp = ref(storage, `image/${file.name}`);
      await uploadBytes(storageRefp, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setpicture(downloadURL);
          setLoadingimg(false);
        });
      });
    }
  };
  const handleCVUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      notify(
        "You cannot upload this file the maximum size of image is 5 mb",
        "warn"
      );
      return;
    } else {
      setLoadingCV(true);
      const storageRefc = ref(storage, `cv/${file.name}`);
      uploadBytes(storageRefc, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setCv(downloadURL);
          setLoadingCV(false);
        });
      });
    }
  };
  const handleSubmit = async () => {
    // Upload the file
    if (
      FullNmae === "" ||
      Nationality === "" ||
      Datee === "" ||
      Address === "" ||
      Phone === "" ||
      Cv === ""
    ) {
      notify("Please Enter the require data", "warn");
      return;
    }
    setLoading(true);

    await addDoc(collection(db, "workwithus"), {
      Address: Address,
      Birthdate: Datee,
      Diseases: Diseases,
      Courses: Courses,
      ComputerCour: Computercours,
      Educationqu: Educationqu,
      Email: Email,
      ForeignLang1: ForeignLang1,
      Level1: Level1,
      ForeignLang2: ForeignLang2,
      Level2: Level2,
      ForeignLang3: ForeignLang3,
      Level3: Level3,
      FullName: FullNmae,
      Handycapping: Handycapping,
      Jobtitle: JobTitle,
      Numberchild: Numberchild,
      Nationality: Nationality,
      Maritalstatus: Maritalstatus,
      picture: picture,
      cv: Cv,
      Teachingex: Teachingex,
      Residentlocation: Residentloc,
      PresentOcc: PresentOcc,
      Phone: Phone,
      level: Level,
      createdAt: Timestamp.now(),
    })
      .then(() => {
        setLoading(false);
        notify("Data Added successfuly", "success");
        setDate("");
        setpicture("");
        setpicturebase64("");
        setCv("");
        setFullNmae("");
        setNationality("");
        setResidentloc("");
        setAddress("");
        setImagesrc("");
        setPhone("");
        setEducationqu("");
        setLevel("");
        setEmail("");
        setJobTitle("");
        setPresentOcc("");
        setForeignLang1("");
        setLevel1("");
        setForeignLang2("");
        setLevel2("");
        setForeignLang3("");
        setLevel3("");
        setTeachingex("");
        setCourses("");
        setComputercours("");
        setMaritalstatus("");
        setNumberchild("");
        setDiseases("");
        setHandycapping("");
      })
      .catch((error) => {
        setLoading(false);
        notify("Please Try Again", "error");
        setDate("");
        setpicture("");
        setpicturebase64("");
        setCv("");
        setFullNmae("");
        setImagesrc("");
        setNationality("");
        setResidentloc("");
        setAddress("");
        setPhone("");
        setEducationqu("");
        setLevel("");
        setEmail("");
        setJobTitle("");
        setPresentOcc("");
        setForeignLang1("");
        setLevel1("");
        setForeignLang2("");
        setLevel2("");
        setForeignLang3("");
        setLevel3("");
        setTeachingex("");
        setCourses("");
        setComputercours("");
        setMaritalstatus("");
        setNumberchild("");
        setDiseases("");
        setHandycapping("");
      });
  };

  const update = (item) => {
    setIsedit(true);
    setId(item.id);
    setDate(item.Birthdate);
    setpicturebase64(item.picture);
    setpicture(item.picture);
    setCv(item.cv);
    setFullNmae(item.FullName);
    setNationality(item.Nationality);
    setResidentloc(item.Residentlocation);
    setAddress(item.Address);
    setPhone(item.Phone);
    setEducationqu(item.Educationqu);
    setLevel(item.level);
    setEmail(item.Email);
    setJobTitle(item.Jobtitle);
    setPresentOcc(item.PresentOcc);
    setForeignLang1(item.ForeignLang1);
    setLevel1(item.Level1);
    setForeignLang2(item.ForeignLang2);
    setLevel2(item.Level2);
    setForeignLang3(item.ForeignLang3);
    setLevel3(item.Level3);
    setTeachingex(item.Teachingex);
    setCourses(item.Courses);
    setComputercours(item.ComputerCour);
    setMaritalstatus(item.Maritalstatus);
    setNumberchild(item.Numberchild);
    setDiseases(item.Diseases);
    setHandycapping(item.Handycapping);
  };

  const handleUpdate = async () => {
    // Upload the file
    if (
      FullNmae === "" ||
      Nationality === "" ||
      Datee === "" ||
      Address === "" ||
      Phone === "" ||
      Cv === ""
    ) {
      notify("Please Enter the require data", "warn");
      return;
    }
    setLoading(true);
    const updateData = doc(db, "workwithus", Id);
    await updateDoc(updateData, {
      Address: Address,
      Birthdate: Datee,
      Diseases: Diseases,
      Courses: Courses,
      ComputerCour: Computercours,
      Educationqu: Educationqu,
      Email: Email,
      ForeignLang1: ForeignLang1,
      Level: Level1,
      ForeignLang2: ForeignLang2,
      Level2: Level2,
      ForeignLang3: ForeignLang3,
      Level3: Level3,
      FullName: FullNmae,
      Handycapping: Handycapping,
      Jobtitle: JobTitle,
      Numberchild: Numberchild,
      Nationality: Nationality,
      Maritalstatus: Maritalstatus,
      picture: picture,
      cv: Cv,
      Teachingex: Teachingex,
      Residentlocation: Residentloc,
      PresentOcc: PresentOcc,
      Phone: Phone,
      level: Level,
      createdAt: Timestamp.now(),
    });
    notify("Event Edited successfuly", "success");
    setDate("");
    setpicture("");
    setpicturebase64("");
    setCv("");
    setId("");
    setImagesrc("");
    setFullNmae("");
    setNationality("");
    setResidentloc("");
    setAddress("");
    setPhone("");
    setEducationqu("");
    setLevel("");
    setEmail("");
    setJobTitle("");
    setPresentOcc("");
    setForeignLang1("");
    setLevel1("");
    setForeignLang2("");
    setLevel2("");
    setForeignLang3("");
    setLevel3("");
    setTeachingex("");
    setCourses("");
    setComputercours("");
    setMaritalstatus("");
    setNumberchild("");
    setDiseases("");
    setHandycapping("");
    setIsedit(false);
    setLoading(false);
  };
  const handleDelete = async () => {
    setLoading(true);

    const deleteVal = doc(db, "workwithus", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    // getBlogs();
    setshowDelete(false);
    setLoading(false);
  };
  return (
    <div>
      {" "}
      <div className="workus">
        <Container>
          <h1 className="tit text-center" style={{ fontFamily: "Almarai" }}>
            {arabic === "true" ? "اعمل معنا" : "Work with us"}
          </h1>
          <hr />
          <div className="inputs-container">
            <h2
              style={{
                display: "block",
                textAlign: "center",
                width: "100%",
                fontFamily: "Almarai",
              }}
            >
              {arabic === "true" ? "البيانات الأساسية" : "Basic Information"}
            </h2>
            <div
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              <div className="proimg">
                <div className="imgprof">
                  {renderPreview()}{" "}
                  {Loadingimg ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : null}
                </div>
                <div className="image-editor">
                  <i className="fas fa-pen editpic"></i>
                  <input
                    type="file"
                    accept="image/*"
                    id="imginput"
                    onChange={fileUpload}
                  />
                </div>
              </div>
            </div>
            <div className="inp-container">
              <TextField
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "الأسم بالكامل" : "Full Name"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                type="text"
                value={FullNmae}
                onChange={(e) => setFullNmae(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                value={Nationality}
                onChange={(e) => setNationality(e.target.value)}
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "الجنسية" : "Nationality"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true" ? "موقع الأقامة" : "Resident Location"
                }`}
                value={Residentloc}
                onChange={(e) => setResidentloc(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <div className="dateee">{Datee ? Datee : null}</div>
              <i className="ty fa-solid fa-calendar-days"></i>
              <TextField
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "تاريخ الميلاد" : "Birth Date"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                type="date"
                value={Datee}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="inp-container">
              <TextField
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "العنوان" : "Address"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                value={Phone}
                onChange={(e) => setPhone(e.target.value)}
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "رقم الهاتف" : "Phone"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                type="number"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                value={Educationqu}
                onChange={(e) => setEducationqu(e.target.value)}
                label={`${
                  arabic === "true"
                    ? "المؤهلات التعليمية"
                    : "Education Qualifications"
                }`}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "المستوى" : "Level"}`}
                value={Level}
                onChange={(e) => setLevel(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "البريد الألكتوني" : "Email"}`}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "عنوان الوظيفة" : "Job Title"}`}
                value={JobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                value={PresentOcc}
                onChange={(e) => setPresentOcc(e.target.value)}
                label={`${
                  arabic === "true" ? "الوظيفة الحالية" : "Present Occupation"
                }`}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                id="outlined-basic"
                type="file"
                focused
                label={
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "السيرة الذاتية" : "CV"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                }
                onChange={handleCVUpload}
              />
              {LoadingCV ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
            </div>
            <div className="inp-container"></div>
          </div>
          <div className="inputs-container">
            <h2
              style={{
                display: "block",
                textAlign: "center",
                width: "100%",
                fontFamily: "Almarai",
              }}
            >
              {arabic === "true" ? "بيانات اضافية" : "Optional Information"}
            </h2>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true"
                    ? "اللغة الأجنبية الأولى"
                    : "Foreign Languages Spoken 1"
                }`}
                type="text"
                variant="outlined"
                value={ForeignLang1}
                onChange={(e) => setForeignLang1(e.target.value)}
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "المستوى" : "Level"}`}
                value={Level1}
                onChange={(e) => setLevel1(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                value={ForeignLang2}
                onChange={(e) => setForeignLang2(e.target.value)}
                label={`${
                  arabic === "true"
                    ? "اللغة الأجنبية الثانية"
                    : "Foreign Languages Spoken 2"
                }`}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "المستوى" : "Level"}`}
                value={Level2}
                onChange={(e) => setLevel2(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true"
                    ? "اللغة الأجنبية الثالثة"
                    : "Foreign Languages Spoken 3"
                }`}
                type="text"
                value={ForeignLang3}
                onChange={(e) => setForeignLang3(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${arabic === "true" ? "المستوى" : "Level"}`}
                value={Level3}
                onChange={(e) => setLevel3(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true"
                    ? "الخبرة في التدريس داخل جمهورية مصر العربية"
                    : "Teaching Experience Inside Arab Republic of Egypt"
                }`}
                type="text"
                variant="outlined"
                value={Teachingex}
                onChange={(e) => setTeachingex(e.target.value)}
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true"
                    ? "الدورات ومواعيد الحصول عليها"
                    : "Courses and its Obtaining dates"
                }`}
                value={Courses}
                onChange={(e) => setCourses(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true" ? "دورات الكمبيوتر" : "Computer Courses"
                }`}
                value={Computercours}
                onChange={(e) => setComputercours(e.target.value)}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  <Typography sx={{ fontSize: "18px", fontFamily: "Almarai" }}>
                    {arabic === "true" ? "الحالة الاجتماعية" : "Marital Status"}
                    <span style={{ color: "red", fontSize: "21px" }}>*</span>
                  </Typography>
                </InputLabel>
                <Select
                  value={Maritalstatus}
                  onChange={(e) => setMaritalstatus(e.target.value)}
                  // value={Chosedur}
                  label={`${
                    arabic === "true" ? "الحالة الاجتماعية" : "Marital Status"
                  }`}
                  // onChange={(e) => setChosedur(e.target.value)}
                >
                  <MenuItem value={"Single"}>Single</MenuItem>
                  <MenuItem value={"Marred"}>Marred</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true" ? "عدد الأبناء" : "Number of children"
                }`}
                type="number"
                value={Numberchild}
                onChange={(e) => setNumberchild(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                value={Diseases}
                onChange={(e) => setDiseases(e.target.value)}
                label={`${
                  arabic === "true"
                    ? "هل لديك أمراض - قم بإدراجها؟"
                    : "Do you have diseases - list it?"
                }`}
                type="text"
                variant="outlined"
              />
            </div>
            <div className="inp-container">
              <TextField
                label={`${
                  arabic === "true"
                    ? "هل أنت من ذوي الهمم - قم بإدراج تحدياتك"
                    : "Do you have handycapping - list it"
                }`}
                type="text"
                value={Handycapping}
                onChange={(e) => setHandycapping(e.target.value)}
                variant="outlined"
              />
            </div>
            <div className="inp-container"></div>{" "}
            <div className="inp-container"></div>{" "}
            <div className="inp-container"></div>
          </div>
          {Isedit ? (
            Loading ? (
              <button className="buttonadd">
                <Spinner />
              </button>
            ) : (
              <button className="buttonadd" onClick={handleUpdate}>
                {arabic === "true" ? "تعديل" : "Edit"}
              </button>
            )
          ) : Loading ? (
            <button className="buttonadd">
              <Spinner />
            </button>
          ) : (
            <button className="buttonadd" onClick={handleSubmit}>
              {arabic === "true" ? "إرسال" : "Send"}
            </button>
          )}
        </Container>{" "}
        <ToastContainer position="top-center" />
      </div>
      <Container>
        <div className="inputs-container ">
          <div className="inp-container search ">
            {" "}
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              onChange={filterBySearch}
              //   className="ww"
            />
            <i className="ty fa-solid fa-magnifying-glass"></i>
          </div>{" "}
          <div className="inp-container"></div>
          <div className="inp-container"></div>
        </div>
      </Container>
      <Container>
        <Table
          ref={tableRef}
          className="maint"
          responsive
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              <th>
                Date <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Full Name <i className="fa-solid fa-user"></i>
              </th>
              <th>
                Nationality <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Phone <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Job Title <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                Profile Picture <i className="fa-solid fa-file-signature"></i>
              </th>
              <th>
                CV<i className="fa-solid fa-file-signature"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredList && filteredList.length >= 0
              ? filteredList.map((item, idx) => (
                  <tr key={idx}>
                    <td>{new Date(item.createdAt.seconds*1000).toLocaleDateString('en-GB')}</td>
                    <td>{item.FullName}</td>
                    <td>{item.Nationality}</td>

                    <td>{item.Phone}</td>
                    <td>{item.Jobtitle}</td>
                    <td>
                      <a href={item.picture}>
                        <i className="fa-solid fa-user"></i>
                      </a>
                    </td>
                    <td>
                      <a href={item.cv}>
                        <i className="fa-solid fa-file"></i>
                      </a>
                    </td>

                    <td className="icon-w">
                      <i
                        onClick={() => update(item)}
                        className="fa fa-solid fa-pen-to-square"
                        style={{ color: "rgb(0 44 146)" }}
                      ></i>
                    </td>
                    <td className="icon-w">
                      {" "}
                      <i
                        onClick={() => handleShoweDelete(item.id)}
                        className="fs fa-solid fa-trash-can"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </Container>
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDelete}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <hr />
      <button onClick={onDownload} className="buttonadd">
        Export
      </button>
    </div>
  );
};

export default Workwithus;
