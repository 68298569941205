import React from "react";
import internet from "../../img/undraw_developer_activity_re_39tg.svg";
import "./internet.css";
const Internet = () => {
  return (
    <div className="internet">
      <h2>No Internet</h2>

      <img src={internet} alt="internet" />
    </div>
  );
};

export default Internet;      
