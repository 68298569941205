import React, { useEffect, useState } from "react";
import "./events.css";
import { Fade } from "react-awesome-reveal";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
const YouetubeEvent = ({ title, desc, link,Arabic }) => {
  const [Eventsyoutube, setEventsyoutube] = useState([]);
  const [EventsyoutubeA, setEventsyoutubeA] = useState([]);

  useEffect(
    () =>
      onSnapshot(query(collection(db, "events"), orderBy("createdAt", "asc")), (snapshot) =>
        setEventsyoutube(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "eventsA"), orderBy("createdAt", "asc")), (snapshot) =>
        setEventsyoutubeA(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      ),
    []
  );
  return (
    <div className="events">
      <section>
        <Link to={"/activites"} style={{ textDecoration: "none" }}>
          {" "}
          <h3 className="tit"> {Arabic === "true" ? "الأنشطة" : "Activities"}</h3>
        </Link>
        {/* <hr/> */}
        {/* <h3 className='tit'>Virtual Touer</h3> */}
        {Arabic === "true"?(
            <div className="container">

            {EventsyoutubeA && EventsyoutubeA.length >= 0
                  ? EventsyoutubeA.slice(0,1).map((item,idx) => (
              <div className="row"key={idx}>
                <div className="col-md-6 ">
                  <Fade direction="left" triggerOnce="false">
                    <h2 className="text-uppercase">{item.headA}</h2>
                    <p>{item.descA}</p>
                    <Link className="mb-3 btn btn-primary text-uppercase" to={link}>
                      المزيد
                    </Link>
                  </Fade> 
                </div>
                <div className="col-md-6">
                  <Fade direction="right" triggerOnce="false">
                    <iframe
                      title="myFramesd"
                      width="80%"
                      height="500"
                      src={`https://www.youtube.com/embed/${item.linkA}`}
                    ></iframe>
                  </Fade>
                </div>
              </div>
              )):null}
            </div>
        ):(
            <div className="container">

            {Eventsyoutube && Eventsyoutube.length >= 0
                  ? Eventsyoutube.slice(0,1).map((item,idx) => (
              <div className="row"key={idx}>
                <div className="col-md-6 ">
                  <Fade direction="left" triggerOnce="false">
                    <h2 className="text-uppercase">{item.head}</h2>
                    <p>{item.desc}</p>
                    <Link className="mb-3 btn btn-primary text-uppercase" to={link}>
                      More
                    </Link>
                  </Fade> 
                </div>
                <div className="col-md-6">
                  <Fade direction="right" triggerOnce="false">
                    <iframe
                      title="myFramesd"
                      width="80%"
                      height="500"
                      src={`https://www.youtube.com/embed/${item.link}`}
                    ></iframe>
                  </Fade>
                </div>
              </div>
              )):null}
            </div>
        )}
      
      </section>
    </div>
  );
};

export default YouetubeEvent;
