import { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { auth } from "../../../firebase";
import { AuthContext } from "../../../context/auth";
function Sidebar({ setArabic, Arabic }) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { user } = useContext(AuthContext);

  const handleShow = () => setShow(true);
  async function signOut() {
    try {
      await auth.signOut();
      window.location.href = "/";
    } catch (error) {
      alert("error signing out: ", error.message);
    }
  }
  const activeLink = () => {
    const link = document.querySelectorAll(".nn a");
    link.forEach((act) => {
      act.addEventListener("click", function () {
        link.forEach((btn) => btn.classList.remove("active"));
        this.classList.add("active");
      });
    });
  };
  useEffect(() => {
    // dispatch(GetAllFilessAction());
    activeLink();
  }, []);
  useEffect(() => {
    if (location && location.pathname !== "/") {
      setShow(false);
    }
  }, [location]);
  return (
    <div className="sidee">
      <div className="nvs">
        <button onClick={handleShow}>
          <i className="fa-solid fa-bars"></i> MENU
        </button>
        <div>
          <span>Welcome,</span> {user.email}
          {/* <input
            type="checkbox"
            value={Arabic}
            style={{marginLeft:"100px"}}
            onChange={(e) => {
              localStorage.setItem("Arabic", e.target.checked);
              setArabic(e.target.checked);
            }}
          /> */}
        </div>
        <button onClick={signOut}>
          <i className="fa-solid fa-right-from-bracket"></i> Logout
        </button>
      </div>
      <Offcanvas className="lli" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Futuer Leaders Schools</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Fade direction="left" triggerOnce="false">
            <div className="links">
              <Link to="/admin/intro">Intro Slide</Link>
              <Link to="/admin/galary">Gallery</Link>
              <Link to="/admin/activities">Activities </Link>
              <Link to="/admin/facilities">Facilities</Link>
              <Link to="/admin/values">OUR VALUES</Link>
              <Link to="/admin/achievements">Our Achievements</Link>
              <Link to="/admin/admission">Admission</Link>
              <Link to="/admin/admissionoptions">Admission Options</Link>
              <Link to="/admin/workwithus">work with us</Link>
              <Link to="/admin/calender">National School Calender </Link>
              <Link to="/admin/calenderamerican">
                American School Calender{" "}
              </Link>
              <Link to="/admin/calenderarabic">Arabic School Calender </Link>
              <Link to="/admin/about">About Us</Link>
              <Link to="/admin/aboutuspage">About Us Page</Link>
              <Link to="/admin/contact">Contact Us</Link>
              <Link to="/admin/socialmedia">Social Media</Link>

            </div>
          </Fade>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Sidebar;
