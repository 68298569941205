import React from "react";
import {  Navigate, Outlet } from "react-router-dom";
const ProtectedRoute = ({ auth, children }) => {
  if (auth===null) {
    return <Navigate to="/" replace />;
  }
 
  return children ? children : <Outlet />;
};
export default ProtectedRoute
