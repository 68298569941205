import React, { useEffect, useState } from "react";
import "./contactus.css";
import "../../table/table.css";
import { Button, Container, Modal, Table } from "react-bootstrap";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../../firebase";
import notify from "../../Notifcation";
import { ToastContainer } from "react-toastify";
const Contactus = () => {
  const [Contacts, setContacts] = useState([]);
  const [showDelete, setshowDelete] = useState(false);
  const [Itemid, setItemid] = useState(false);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShoweDelete = (itemid) => {
    setshowDelete(true);
    setItemid(itemid);
  };
  useEffect(
    () =>
      onSnapshot(query(collection(db, "contact"), orderBy("createdAt", "asc")), (snapshot) =>
        setContacts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  const handleDelete = async () => {
    const deleteVal = doc(db, "contact", Itemid);
    await deleteDoc(deleteVal);
    notify("Event Deleted successfuly", "success");
    setshowDelete(false);
    setItemid("")
  };
  return (
    <Container>
      <Table className="maint" responsive striped bordered hover>
        <thead>
          <tr>
            <th>
              Name <i className="fa-solid fa-user"></i>
            </th>
            <th>
              Email <i className="fa-solid fa-layer-group"></i>
            </th>
            <th>
              Message <i className="fa-solid fa-file-signature"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Contacts && Contacts.length >= 0
            ? Contacts.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.message}</td>
                  <td className="icon-w">
                    {" "}
                    <i
                      onClick={() => handleShoweDelete(item.id)}
                      className="fs fa-solid fa-trash-can"
                      style={{ color: "red" }}
                    ></i>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <ToastContainer position="top-center" />
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        centered
        contentClassName="cont"
        keyboard={false}
        style={{ width: "100%", margin: "0px auto", textAlign: "center" }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Do you want to delete this Item ?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="close"
            onClick={handleCloseDelete}
          >
            Close
          </Button>
          <Button variant="primary" className="edit" onClick={handleDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Contactus;
