import React, { useEffect, useState } from "react";
import "./featuer.css";
import { Fade } from "react-awesome-reveal";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { Container } from "react-bootstrap";
const Featuer = ({ Arabic }) => {
  const [Feature, setFeature] = useState([]);
  const [FeatureA, setFeatureA] = useState([]);

  useEffect(
    () =>
      onSnapshot(query(collection(db, "feature"), orderBy("createdAt", "asc")), (snapshot) =>
        setFeature(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "featureA"), orderBy("createdAt", "asc")), (snapshot) =>
        setFeatureA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );
  return (
    <div className="features">
      <h3 className="tit">{Arabic === "true" ? "قيمنا" : "OUR VALUES"}</h3>
      <Container>
        {Arabic === "true" ? (
          <div className="fetcont">
            {FeatureA && FeatureA.length >= 0
              ? FeatureA.map((item, idx) => (
                  <div className="feat-box" key={idx}>
                    <Fade direction="up" triggerOnce="false">
                      <img src={item.iconA} alt="" />
                    </Fade>
                    <Fade direction="left" triggerOnce="false">
                      <h4>{item.headA}</h4>
                      <p>{item.descA}</p>
                    </Fade>
                  </div>
                ))
              : null}
          </div>
        ) : (
          <div className="fetcont">
            {Feature && Feature.length >= 0
              ? Feature.map((item, idx) => (
                  <div className="feat-box" key={idx}>
                    <Fade direction="up" triggerOnce="false">
                      <img src={item.icon} alt="" />
                    </Fade>
                    <Fade direction="left" triggerOnce="false">
                      <h4>{item.head}</h4>
                      <p>{item.desc}</p>
                    </Fade>
                  </div>
                ))
              : null}
          </div>
        )}

        <div className="clearfix"></div>
      </Container>
    </div>
  );
};

export default Featuer;
