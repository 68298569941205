import React, { useEffect, useState } from "react";
import "./Popupflag.css";
import { Fade } from "react-awesome-reveal";
import { Container } from "react-bootstrap";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
const Popupflag = ({Arabic}) => {
  const [Blogs, setBlogs] = useState([]);
  const [BlogsA, setBlogsA] = useState([]);

  useEffect(
    () =>
      onSnapshot(query(collection(db, "blogA"), orderBy("createdAt", "asc")), (snapshot) =>
        setBlogsA(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    [] 
  );
  useEffect(
    () =>
      onSnapshot(query(collection(db, "blog"), orderBy("createdAt", "asc")), (snapshot) =>
        setBlogs(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    [] 
  );
  return (
    <div className="Popupflag pagg">
      {Arabic === "true"? <h3 className="tit">ألبوم الصور</h3>:<h3 className="tit">Gallery</h3>}
      <Container>
      <Fade direction="up" triggerOnce="false">
        <main className="Popup">
 
       
          {Arabic === "true" ? (<>
            {BlogsA && BlogsA.length >= 0
              ? BlogsA.map((item,idx) => (
                  <div className="gala"key={idx}>
                    <div className="overlayegala"></div>
                    <img src={item.imgA} alt="Mountains" />
                    <div className="cont">
                      <h1>{item.titleA}</h1>
                      <p>{item.descA}</p>
                    </div>
                  </div>
                ))
              : null}</>) : (<>
            {Blogs && Blogs.length >= 0
              ? Blogs.map((item,idx) => (
                  <div className="gala"key={idx}>
                    <div className="overlayegala"></div>
                    <img src={item.img} alt="Mountains" />
                    <div className="cont">
                      <h1>{item.title}</h1>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                ))
              : null}</>)}
            
      
        </main>
        </Fade>
      </Container>
    </div>
  );
};

export default Popupflag;
